import { useEffect, useRef, useState } from "react";

export function useDebouncedState<T>(delayMillis: number, initialState: T): [T, T, (value: T) => void] {
  const timeout = useRef<number>();
  const [current, setCurrent] = useState(initialState);
  const [debounced, setDebounced] = useState(initialState);
  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => setDebounced(current), delayMillis);
  }, [current, setDebounced]);
  return [current, debounced, setCurrent];
}
