import { ListingProjectCell, ProjectId } from "../common/types";
import {
  EditableColumnId,
  EditableColumnSetValue,
  CommittedCostsEdit,
  EstRecognitionEdit,
  InvoicingEdit,
  OrdersReceivedEdit,
  PTDVariancesEdit,
  RatesEdit,
  RecognitionEdit,
  RecPercentEdit,
  SalesCostsEdit,
  RelatedEstRecEdit,
  EditColumnValueTypeMap,
  EditableColumn,
} from "../common/columnsTypes";
import {
  EditableRecognitionCell,
  RecognitionCellAdditionalInfo,
  EditableRecognitionCellComment,
  EditableRecognitionCellFreezing,
  EditableRecognitionCellT,
  EditableRecognitionRow,
  ProjectRecognitionWaitingForReady,
  ProjectRecognitionWaitingForReadyMap,
  RecognitionEditOptions,
  RecognitionsEditState,
  RecognitionsEditStateChange,
} from "../components/sites/Project/ProjectRecognitions/types";
import {
  CHANGE_RECOGNITIONS_WAITING_FOR_READY,
  END_RECOGNITIONS_EDITING,
  END_RECOGNITIONS_MASS_FREEZE,
  INITIALIZE_RECOGNITIONS_EDIT_STATE,
  INITIALIZE_RECOGNITIONS_EDIT_TYPE,
  INITIALIZE_RECOGNITIONS_WAITING_FOR_READY,
  InitializeRecognitionsEditState,
  InitializeRecognitionsWaitingForReady,
  RECOGNITIONS_ADD_FREEZING,
  RECOGNITIONS_COPY_EST_REC_NS_TO_COGS,
  RECOGNITIONS_EDIT_CALCULATE,
  RECOGNITIONS_EDIT_CHANGE,
  RECOGNITIONS_EDIT_COPY_CURRENT,
  RECOGNITIONS_HAS_ERROR,
  RECOGNITIONS_REMOVE_FREEZING,
  RecognitionsAction,
  RecognitionsEditChange,
  recognitionsEditChange,
  RecognitionsEditCopyCurrent,
  RecognitionsHasError,
  START_RECOGNITIONS_EDITING,
  START_RECOGNITIONS_MASS_FREEZE,
  ValueChange,
} from "../actions/recognitionsEditActions";
import { FREEZING_COMMENT_TYPE, REC_WAIT_FOR_READY_LOCAL_STORAGE_KEY } from "../common/constants";

import {
  initState,
  RECOGNITION_EDIT_COLUMNS,
  RecPlanEstRecEditModes,
} from "../components/sites/Project/ProjectRecognitions/recognitionsEditingSetting";
import { currentPeriod, parsePeriodOrFail, Period, periodEquals, periodGreaterThan } from "../common/period";
import { chain, clone, filter, remove, some } from "lodash";

import { getUserCookie } from "../common/restApi";
import produce from "immer";

function recognitionsEditChangeKey(change: { period: Period; columnId: string; field: string }): string {
  return `${change.period.year}_${change.period.month}_${change.columnId}_${change.field}`;
}

const calculateErrors = (state: RecognitionsEditState, fieldError: RecognitionsHasError): RecognitionsEditState => {
  return produce(state, newState => {
    if (fieldError.hasError) {
      if (!newState.recognitionsErrors.includes(fieldError.id)) {
        newState.recognitionsErrors.push(fieldError.id);
      }
    } else {
      newState.recognitionsErrors = filter(newState.recognitionsErrors, errorId => errorId !== fieldError.id);
    }
  });
};

const getCellData = (
  recognitions: EditableRecognitionRow[],
  period: Period,
  columnId: EditableColumnId
): EditableRecognitionCell | undefined => {
  const periodData = recognitions.find(r => periodEquals(r.period, period));
  return periodData ? periodData.findCell(columnId) : undefined;
};

const calculateRecNsPercent = (recognitionNs: number, estimateNs: number): number => {
  const result = (recognitionNs / estimateNs) * 100;
  return !isFinite(result) || isNaN(result) ? 0 : result;
};

const calculateRecCogsPercent = (recognitionCogs: number, estimateCost: number): number => {
  const result = (recognitionCogs / estimateCost) * 100;
  return !isFinite(result) || isNaN(result) ? 0 : result;
};

const calculateRecognisedNs = (recNsPercent: number, estimateNs: number): number => {
  const result = (recNsPercent / 100.0) * estimateNs;
  return !isFinite(result) || isNaN(result) ? 0 : result;
};

const calculateRecognisedCogs = (recCogsPercent: number, estimateCost: number): number => {
  const result = (recCogsPercent / 100.0) * estimateCost;
  return !isFinite(result) || isNaN(result) ? 0 : result;
};

const calculateWipCosts = (recCosts: number, estCosts: number, estWarrantyCosts: number): number => {
  if (estCosts === 0) {
    return 0;
  } else {
    return recCosts - (recCosts / estCosts) * estWarrantyCosts;
  }
};

const getCells = <CA extends EditableColumnId, CB extends EditableColumnId, CC extends EditableColumnId>(
  row: EditableRecognitionRow,
  colA: CA,
  colB: CB,
  colC: CC
): [
  EditableRecognitionCellT<EditColumnValueTypeMap[CA]> | null,
  EditableRecognitionCellT<EditColumnValueTypeMap[CB]> | null,
  EditableRecognitionCellT<EditColumnValueTypeMap[CC]> | null
] => {
  const aCell = row.findCell(colA);
  const bCell = row.findCell(colB);
  const cCell = row.findCell(colC);
  if (!aCell || !bCell || !cCell) {
    console.error(
      `Missing edit cells ${colA} found ${aCell !== undefined}, ${colB} found ${bCell !== undefined}, ${colC} found ${
        cCell !== undefined
      }`
    );
    return [null, null, null];
  }
  return [aCell, bCell, cCell];
};

const rowCalculations = (
  row: EditableRecognitionRow,
  editedColumn: EditableColumnId,
  editedField: string,
  estRecEditMode: RecPlanEstRecEditModes
): RecognitionsEditChange[] => {
  const mkChange = (columnId: EditableColumnId, field: string, value: number): RecognitionsEditChange => ({
    type: "RECOGNITIONS_EDIT_CHANGE",
    period: clone(row.period),
    columnId,
    field,
    value,
  });

  const estRecPercentCell = row.findCell("est_rec_percent_edit");
  const estRecCell = row.findCell("est_rec_edit");
  const estimateCell = row.findCell("est_edit");

  if (!estRecPercentCell || !estRecCell || !estimateCell) return [];

  const estRecPercentValue = estRecPercentCell.value;
  const estRecValue = estRecCell.value;
  const estimateValue = estimateCell.value;

  switch (editedColumn) {
    case "est_rec_percent_edit": {
      if (editedField === "nsPercent") {
        const estRecNs = calculateRecognisedNs(estRecPercentValue.nsPercent, estimateValue.ns);
        return [mkChange("est_rec_edit", "ns", estRecNs)];
      } else if (editedField === "cogsPercent" && estRecEditMode === RecPlanEstRecEditModes.NsCogsPercentage) {
        const estRecCogs = calculateRecognisedCogs(estRecPercentValue.cogsPercent, estimateValue.cost);
        const estWipCosts = calculateWipCosts(estRecCogs, estimateValue.cost, estimateValue.war);
        return [mkChange("est_rec_edit", "cogs", estRecCogs), mkChange("est_rec_edit", "wipCosts", estWipCosts)];
      } else if (editedField === "cogsPercent" && estRecEditMode === RecPlanEstRecEditModes.CogsPercentage) {
        const estRecCogs = calculateRecognisedCogs(estRecPercentValue.cogsPercent, estimateValue.cost);
        const estWipCosts = calculateWipCosts(estRecCogs, estimateValue.cost, estimateValue.war);
        const estRecNsPercent = estRecPercentValue.cogsPercent;
        const estRecNs = calculateRecognisedNs(estRecNsPercent, estimateValue.ns);
        return [
          mkChange("est_rec_percent_edit", "nsPercent", estRecNsPercent),
          mkChange("est_rec_edit", "cogs", estRecCogs),
          mkChange("est_rec_edit", "wipCosts", estWipCosts),
          mkChange("est_rec_edit", "ns", estRecNs),
        ];
      }
      break;
    }

    case "est_rec_edit": {
      if (editedField === "wipCosts") {
        const estNs = estimateValue.ns;
        const estCost = estimateValue.cost;
        const estWarrantyCost = estimateValue.war;
        const wipCosts = estRecValue.wipCosts;

        const estRecNsCogsPercent = calculateRecCogsPercent(wipCosts, estCost - estWarrantyCost);
        const estRecNs = calculateRecognisedNs(estRecNsCogsPercent, estNs);
        const estRecCogs = calculateRecognisedCogs(estRecNsCogsPercent, estCost);

        return [
          mkChange("est_rec_percent_edit", "nsPercent", estRecNsCogsPercent),
          mkChange("est_rec_percent_edit", "cogsPercent", estRecNsCogsPercent),
          mkChange("est_rec_edit", "ns", estRecNs),
          mkChange("est_rec_edit", "cogs", estRecCogs),
        ];
      } else if (editedField === "ns") {
        const estRecNsCogsPercent = calculateRecNsPercent(estRecValue.ns, estimateValue.ns);
        const estRecCogs = calculateRecognisedCogs(estRecNsCogsPercent, estimateValue.cost);
        const estWipCosts = calculateWipCosts(estRecCogs, estimateValue.cost, estimateValue.war);
        return [
          mkChange("est_rec_percent_edit", "nsPercent", estRecNsCogsPercent),
          mkChange("est_rec_percent_edit", "cogsPercent", estRecNsCogsPercent),
          mkChange("est_rec_edit", "cogs", estRecCogs),
          mkChange("est_rec_edit", "wipCosts", estWipCosts),
        ];
      }
      break;
    }

    case "est_edit": {
      if (editedField === "ns") {
        return [mkChange("est_rec_edit", "ns", (estRecPercentValue.nsPercent / 100) * estimateValue.ns)];
      } else if (editedField === "cost") {
        return [mkChange("est_rec_edit", "cogs", (estRecPercentValue.cogsPercent / 100.0) * estimateValue.cost)];
      }
      break;
    }
  }
  return [];
};

const calculateRec = (row: EditableRecognitionRow): EditableRecognitionRow => {
  const recPercentCell = row.findCell("est_rec_percent_edit");
  const recognitionCell = row.findCell("est_rec_edit");
  const estimateCell = row.findCell("est_edit");
  if (!recPercentCell || !recognitionCell || !estimateCell) return row;

  const recValue = recPercentCell.value;

  if (row.additionalInfo && row.additionalInfo.ptProjectRecOverriddenFromRelated) {
    const relatedEstRec = row.getCell("related_est_rec_edit");
    recValue.nsPercent = relatedEstRec.value.nsPercent;
    recValue.cogsPercent = relatedEstRec.value.nsPercent;
  } else {
    const recognitionValue = recognitionCell.value;
    const estimateValue = estimateCell.value;

    recValue.nsPercent = calculateRecNsPercent(recognitionValue.ns, estimateValue.ns);
    recValue.cogsPercent = calculateRecCogsPercent(recognitionValue.cogs, estimateValue.cost);
  }

  return row;
};

const updateChangeToState = (state: RecognitionsEditState, change: ValueChange) => {
  const initialCell = getCellData(state.initialRecognitions, change.period, change.columnId);
  const initialCellValue = initialCell && initialCell.value;
  const cellToChange = getCellData(state.recognitions, change.period, change.columnId);
  const cellToChangeValue = cellToChange && cellToChange.value;

  if (
    initialCell &&
    initialCellValue &&
    initialCellValue[change.field] !== undefined &&
    cellToChange &&
    cellToChangeValue &&
    cellToChangeValue[change.field] !== undefined
  ) {
    const initialFreezing = initialCell.freezings.find(f => f.fieldName === change.field);
    const initialComments = initialCell.comments.find(f => f.fieldName === change.field);
    const initialComment = initialComments && initialComments.comments.find(v => v.type === FREEZING_COMMENT_TYPE);

    const initialValueIsSame = change.value === initialCellValue[change.field];
    const initialFreezingIsSame = change.freezingComment
      ? initialFreezing !== undefined &&
        initialComment !== undefined &&
        initialFreezing.value === change.value &&
        initialComment.content === change.freezingComment
      : initialFreezing === undefined;
    const sameAsInitial = initialValueIsSame && initialFreezingIsSame;

    const value = change.value !== undefined ? change.value : cellToChangeValue[change.field];
    cellToChangeValue[change.field] = value;

    // Clean freezing and freezing comment for this field
    remove(cellToChange.freezings, v => v.fieldName === change.field);
    const fieldComments = cellToChange.comments.find(v => v.fieldName === change.field);
    if (fieldComments) remove(fieldComments.comments, v => v.type === FREEZING_COMMENT_TYPE);

    const changeKey = recognitionsEditChangeKey(change);
    if (sameAsInitial) {
      state.changes.delete(changeKey);
    } else {
      console.log("Adding new change:", changeKey, change);
      state.changes.set(changeKey, { ...change, value });

      if (change.freezingComment) {
        const newFreezing: EditableRecognitionCellFreezing = {
          fieldName: change.field,
          value,
          createdBy: state.user ? state.user.displayName : "unknown",
        };
        const newComment: EditableRecognitionCellComment = {
          type: FREEZING_COMMENT_TYPE,
          content: change.freezingComment,
          createdBy: state.user ? state.user.displayName : "unknown",
        };
        cellToChange.freezings.push(newFreezing);
        if (fieldComments) fieldComments.comments.push(newComment);
        else cellToChange.comments.push({ fieldName: change.field, comments: [newComment] });
      }
    }
  } else {
    console.error(
      `Invalid recognitions table change ${change.period.year}-${change.period.month} ${change.columnId} ${change.field}`
    );
  }
};

const doRowCalculations = (
  state: RecognitionsEditState,
  change: RecognitionsEditStateChange,
  estRecEditMode: RecPlanEstRecEditModes
) => {
  const row = state.recognitions.find(v => periodEquals(v.period, change.period));
  const rowCalculationChanges = row && rowCalculations(row, change.columnId, change.field, estRecEditMode);
  if (rowCalculationChanges) {
    rowCalculationChanges.forEach(change => updateChangeToState(state, change));
  }
};

const isFieldFrozenForRow = (row: EditableRecognitionRow, columnId: string, field: string): boolean =>
  some(row.cells, cell => cell.columnId === columnId && some(cell.freezings, freezing => freezing.fieldName === field));

const copyToAllPeriods = (
  state: RecognitionsEditState,
  change: RecognitionsEditStateChange,
  estRecEditMode: RecPlanEstRecEditModes
) => {
  state.recognitions
    .filter(row => !periodEquals(row.period, change.period) && !isFieldFrozenForRow(row, change.columnId, change.field))
    .forEach(row => {
      const copyChange = { ...change, period: clone(row.period) };
      updateChangeToState(state, copyChange);
      doRowCalculations(state, copyChange, estRecEditMode);
    });
};

const copyToFuturePeriods = (
  state: RecognitionsEditState,
  change: RecognitionsEditStateChange,
  estRecEditMode: RecPlanEstRecEditModes
) => {
  const thisPeriod = currentPeriod();
  const changeInThePast = periodGreaterThan(thisPeriod, change.period);

  if (changeInThePast) return;

  chain(state.recognitions)
    .filter(row => periodGreaterThan(row.period, change.period))
    .takeWhile(row => !isFieldFrozenForRow(row, change.columnId, change.field))
    .forEach(row => {
      const copyChange = { ...change, period: clone(row.period) };
      updateChangeToState(state, copyChange);
      doRowCalculations(state, copyChange, estRecEditMode);
    })
    .value();
};

const copyToFutureSmallerPeriods = (
  state: RecognitionsEditState,
  change: RecognitionsEditStateChange,
  estRecEditMode: RecPlanEstRecEditModes
) => {
  const thisPeriod = currentPeriod();
  const changeInThePast = periodGreaterThan(thisPeriod, change.period);

  if (changeInThePast) return;

  if (change.columnId === "est_rec_percent_edit") {
    // VPOP-959 If user made a change to the est rec percentages, that are larger than 100%,
    // this should not be copied to future periods.
    if (change.value > 100.0) return;
  }

  chain(state.recognitions)
    .filter(row => periodGreaterThan(row.period, change.period))
    .takeWhile(row => {
      const data = row.findCell(change.columnId);
      const value = data && data.value[change.field];
      if (typeof value !== "number") return false;
      return value < change.value && !isFieldFrozenForRow(row, change.columnId, change.field);
    })
    .forEach(row => {
      const copyChange = { ...change, period: row.period };
      updateChangeToState(state, copyChange);
      doRowCalculations(state, copyChange, estRecEditMode);
    })
    .value();
};

const forceCopyToFollowingPeriods = (
  state: RecognitionsEditState,
  change: RecognitionsEditCopyCurrent,
  estRecEditMode: RecPlanEstRecEditModes
) => {
  chain(state.recognitions)
    .filter(row => periodGreaterThan(row.period, change.period))
    .takeWhile(row => !isFieldFrozenForRow(row, change.columnId, change.field))
    .forEach(row => {
      const copyChange = { ...change, period: row.period };
      updateChangeToState(state, copyChange);
      doRowCalculations(state, copyChange, estRecEditMode);
    })
    .value();
};

const changeRecognitions = (
  state: RecognitionsEditState,
  change: RecognitionsEditStateChange
): RecognitionsEditState => {
  return produce(state, newState => {
    updateChangeToState(newState, change);
    newState.latestChange = change;
  });
};

const copyCurrent = (state: RecognitionsEditState, current: RecognitionsEditCopyCurrent): RecognitionsEditState => {
  return produce(state, newState => {
    forceCopyToFollowingPeriods(newState, current, current.estRecEditMode);
    return newState;
  });
};

const calculateRecognitions = (
  state: RecognitionsEditState,
  estRecEditMode: RecPlanEstRecEditModes
): RecognitionsEditState => {
  if (!state.latestChange) return state;

  // Remove freezing comment from change. We don't want to add freezing to calculated columns
  const change = { ...state.latestChange, freezingComment: undefined };

  return produce(state, newState => {
    newState.latestChange = null;
    doRowCalculations(newState, change, estRecEditMode);

    const copyMode = RECOGNITION_EDIT_COLUMNS.getCopyModeForField(
      state.options.editProjectType,
      change.columnId,
      change.field
    );
    switch (copyMode) {
      case "all":
        copyToAllPeriods(newState, change, estRecEditMode);
        break;
      case "future":
        copyToFuturePeriods(newState, change, estRecEditMode);
        break;
      case "future_smaller":
        copyToFutureSmallerPeriods(newState, change, estRecEditMode);
        break;
      case "none":
        break;
    }
  });
};

const initializeRecognitions = (
  state: RecognitionsEditState,
  action: InitializeRecognitionsEditState
): RecognitionsEditState => {
  if (action.recognitions === null) return initState;

  const reduceCells = (byMonthEditableColumns: EditableRecognitionCell[]) => {
    return byMonthEditableColumns.reduce((acc, { columnId, value, comments, freezings }) => {
      switch (columnId) {
        case "as_sold_edit":
        case "budget_edit":
        case "revised_edit":
        case "est_edit":
          acc.push({ columnId, value: value as SalesCostsEdit, comments, freezings });
          break;
        case "est_rec_edit":
          acc.push({ columnId, value: value as EstRecognitionEdit, comments, freezings });
          break;
        case "rec_edit":
          acc.push({ columnId, value: value as RecognitionEdit, comments, freezings });
          break;
        case "invoicing_edit":
          acc.push({ columnId, value: value as InvoicingEdit, comments, freezings });
          break;
        case "comm_costs_edit":
        case "comm_costs_warr_edit":
          acc.push({ columnId, value: value as CommittedCostsEdit, comments, freezings });
          break;
        case "ptd_variances_edit":
          acc.push({ columnId, value: value as PTDVariancesEdit, comments, freezings });
          break;
        case "orders_received_edit":
          acc.push({ columnId, value: value as OrdersReceivedEdit, comments, freezings });
          break;
        case "rates_edit":
          acc.push({ columnId, value: value as RatesEdit, comments, freezings });
          break;
        case "related_est_rec_edit":
          acc.push({ columnId, value: value as RelatedEstRecEdit, comments, freezings });
      }
      return acc;
    }, [] as EditableRecognitionCell[]);
  };
  const convertToEditable = (column: ListingProjectCell): EditableRecognitionCell => {
    const { columnId, value, comments, freezings } = column;
    return {
      columnId: columnId as EditableColumnId,
      value: value as EditableColumnSetValue,
      comments,
      freezings,
    };
  };

  function addAdditionalInfo(row: EditableRecognitionRow, options: RecognitionEditOptions): EditableRecognitionRow {
    if (options.isPTProjectWithEstRecFromRelated && !periodGreaterThan(currentPeriod(), row.period)) {
      const relatedEstRec = row.getCell("related_est_rec_edit");
      const est = row.getCell("est_edit");
      const estRec = row.getCell("est_rec_edit");

      const estRecPercent = calculateRecNsPercent(estRec.value.ns, est.value.ns);
      if (relatedEstRec.value.nsPercent > estRecPercent) {
        const newCells = row.cells.map(cell => {
          if (cell.columnId === "est_rec_percent_edit") {
            return {
              ...cell,
              additionalInfo: {
                message: [
                  {
                    fieldId: "nsPercent",
                    message: "Estimated recognised NS% overridden from related projects for PT project.",
                  },
                  {
                    fieldId: "cogsPercent",
                    message: "Estimated recognised COGS% overridden from related projects for PT project.",
                  },
                ],
              },
            };
          } else {
            return cell;
          }
        });

        const additionalInfo = {
          ptProjectRecOverriddenFromRelated: true,
        };
        return EditableRecognitionRow.withAdditionalInfo(row.period, newCells, additionalInfo);
      }
    }
    return row;
  }

  // Flatten recognition rows
  const recognitions: EditableRecognitionRow[] = action.recognitions.grouped
    .flatMap(byYear =>
      byYear.subGroups.flatMap(byQuarter =>
        byQuarter.subGroups.map(byMonth => {
          const byMonthEditableColumns: EditableRecognitionCell[] = byMonth.columns.map(convertToEditable);
          return {
            period: parsePeriodOrFail(byMonth.period),
            cells: reduceCells(byMonthEditableColumns),
          };
        })
      )
    )
    .map(({ period, cells }) => {
      const estRecPercentEdit: EditableRecognitionCell = {
        columnId: "est_rec_percent_edit",
        value: { nsPercent: 0, cogsPercent: 0 } as RecPercentEdit,
        comments: [],
        freezings: [],
      };
      const allCells = [estRecPercentEdit, ...cells];
      return calculateRec(addAdditionalInfo(new EditableRecognitionRow(period, allCells), action.options));
    });

  return {
    ...state,
    projectId: action.recognitions.id,
    initialized: false,
    initialRecognitions: recognitions,
    recognitions: recognitions,
    changes: new Map(),
    options: action.options,
    user: getUserCookie(),
  };
};

const doRowCalculationsForCopy = (
  state: RecognitionsEditState,
  row: EditableRecognitionRow,
  change: RecognitionsEditStateChange,
  estRecEditMode: RecPlanEstRecEditModes
) => {
  const rowCalculationChanges = row && rowCalculations(row, change.columnId, change.field, estRecEditMode);
  if (rowCalculationChanges) {
    console.log("Update change to state:", rowCalculationChanges, "previous changes in state:", state.changes);
    rowCalculationChanges.forEach(change => updateChangeToState(state, change));
  }
};

const copyEstRecNsToCogs = (
  newState: RecognitionsEditState,
  periods: Period[],
  estRecEditMode: RecPlanEstRecEditModes
): RecognitionsEditState => {
  const changes: [EditableRecognitionRow, RecognitionsEditChange][] = [];
  newState.recognitions.forEach(row => {
    if (periods.find(p => periodEquals(p, row.period))) {
      const estRecPercent = row.getCell("est_rec_percent_edit");
      const value = estRecPercent.value as RecPercentEdit;
      const change: RecognitionsEditChange = recognitionsEditChange(
        row.period,
        "est_rec_percent_edit",
        "cogsPercent",
        value.nsPercent
      );
      changes.push([row, change]);
      value.cogsPercent = value.nsPercent;
    }
  });
  changes.forEach(([row, change]) => doRowCalculationsForCopy(newState, row, change, estRecEditMode));
  console.log("New changes:", newState.changes);
  newState.saveChanges = Array.from(newState.changes.values());
  return newState;
};

const waitingForReadyMap = (): ProjectRecognitionWaitingForReadyMap => {
  const waitForReadyStr = localStorage.getItem(REC_WAIT_FOR_READY_LOCAL_STORAGE_KEY);
  return waitForReadyStr ? new Map(JSON.parse(waitForReadyStr)) : new Map();
};

const waitingForReadyByProjectId = (projectId: ProjectId): ProjectRecognitionWaitingForReady | null => {
  const map = waitingForReadyMap();
  return map.get(projectId) || null;
};

const setWaitingForReadyByProjectId = (
  projectId: ProjectId,
  waitForReady: ProjectRecognitionWaitingForReady | null
) => {
  const waitForReadyMap = waitingForReadyMap();
  waitForReady ? waitForReadyMap.set(projectId, waitForReady) : waitForReadyMap.delete(projectId);
  const newMapStr = JSON.stringify(Array.from(waitForReadyMap.entries()));
  localStorage.setItem(REC_WAIT_FOR_READY_LOCAL_STORAGE_KEY, newMapStr);
};

const initializeWaitingForReady = (
  state: RecognitionsEditState,
  action: InitializeRecognitionsWaitingForReady
): RecognitionsEditState => {
  const projectId = action.projectId;
  const localStorage = projectId ? waitingForReadyByProjectId(projectId) : null;
  // clear local storage of this project, if applicationModifiedDateTime is null, because we can't continue polling for ready without this value.
  const initBasedOnLocalStorage = () => {
    if (projectId && localStorage && !localStorage.applicationModifiedDateTime) {
      setWaitingForReadyByProjectId(projectId, null);
      return null;
    } else return localStorage;
  };
  return {
    ...state,
    waitingForReady: initBasedOnLocalStorage(),
  };
};

export default function recognitionsEditReducer(
  state: Readonly<RecognitionsEditState> = initState,
  action: RecognitionsAction
): RecognitionsEditState {
  switch (action.type) {
    case INITIALIZE_RECOGNITIONS_EDIT_TYPE:
      return produce(state, newState => {
        newState.options.editProjectType = action.editType;
      });

    case INITIALIZE_RECOGNITIONS_EDIT_STATE:
      try {
        return initializeRecognitions(state, action);
      } catch (ex) {
        console.error("Failed to initialize recognitions", action, ex);
        throw ex;
      }

    case INITIALIZE_RECOGNITIONS_WAITING_FOR_READY:
      return initializeWaitingForReady(state, action);

    case RECOGNITIONS_EDIT_CHANGE:
      return changeRecognitions(state, action);

    case RECOGNITIONS_EDIT_COPY_CURRENT:
      return copyCurrent(state, action);

    case RECOGNITIONS_EDIT_CALCULATE:
      return calculateRecognitions(state, action.estRecEditMode);

    case START_RECOGNITIONS_EDITING:
      return { ...state, editing: true, initialized: true };

    case END_RECOGNITIONS_EDITING:
      return initState;

    case RECOGNITIONS_ADD_FREEZING: {
      const newState = calculateRecognitions(changeRecognitions(state, action), action.estRecEditMode);
      return newState;
    }

    case RECOGNITIONS_REMOVE_FREEZING:
      return produce(state, newState => updateChangeToState(newState, action));

    case RECOGNITIONS_HAS_ERROR:
      return calculateErrors(state, action);

    case RECOGNITIONS_COPY_EST_REC_NS_TO_COGS:
      return produce(state, newState => copyEstRecNsToCogs(newState, action.periods, action.estRecEditMode));

    case START_RECOGNITIONS_MASS_FREEZE:
      return produce(state, newState => {
        const { fromPeriod, toPeriod } = action;
        newState.massFreeze = {
          dialogOpen: true,
          fromPeriod,
          toPeriod,
        };
        return newState;
      });
    case END_RECOGNITIONS_MASS_FREEZE:
      return produce(state, newState => {
        newState.massFreeze.dialogOpen = false;
        return newState;
      });
    case CHANGE_RECOGNITIONS_WAITING_FOR_READY:
      return produce(state, newState => {
        setWaitingForReadyByProjectId(action.projectId, action.waitForReady);
        newState.waitingForReady = action.waitForReady;
      });
    default:
      return state;
  }
}
