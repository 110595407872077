import gql from "graphql-tag";
import { FieldPropertiesFields } from "../queries";

export const GET_PROJECT_RELATION_EDIT_INFORMATION = gql`
  query GetProjectRelationEditInformation($projectId: ProjectId!) {
    projectDetailsEditInformation(projectId: $projectId) {
      relations {
        values {
          projectRelationId
          relationType
          company
          relatingKeys
        }
        properties {
          ...FieldPropertiesFields
        }
      }
    }
  }
  ${FieldPropertiesFields}
`;

export const GET_COMPANIES = gql`
  query GetCompanies {
    companies {
      code
      sourceSystem
    }
    baanPcsCompanies {
      code
      sourceSystem
    }
    baanSoCompanies {
      code
      sourceSystem
    }
  }
`;

export const GET_RELATION_TYPES = gql`
  query GetRelationTypes {
    relationTypes {
      relationType
      displayName
      sourceSystem
      selectable
      relatingKeys {
        desc
        kind
      }
    }
  }
`;

export const SAVE_PROJECT_RELATIONS = gql`
  mutation SaveProjectRelations($projectId: ProjectId!, $relations: [ProjectRelationInput!]!) {
    saveProjectRelations(projectId: $projectId, relations: $relations) {
      result {
        projectRelationId
        relationType
        company
        relatingKeys
      }
      errors
      applicationModifiedDateTime
    }
  }
`;

export const SEARCH_RELATION_PROJECTS = gql`
  query SearchRelationProjects($relationType: ProjectRelationType!, $company: CompanyCode!, $searchText: String!) {
    searchRelationProjects(relationType: $relationType, company: $company, searchText: $searchText) {
      project
      subProjects
      line
    }
  }
`;

export const GET_IN_USE_RELATION_PROJECTS = gql`
  query GetInUseRelationProjects($relationType: ProjectRelationType!, $company: CompanyCode!) {
    getInUseRelationProjects(relationType: $relationType, company: $company) {
      project
      subProjects
      line
    }
  }
`;
