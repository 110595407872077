import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  greyTransparent,
  valmetGreyBorder,
  defaultGrey,
  filterGreen,
  settingGreen,
} from "../../../../../common/colors";
import { useApolloClient } from "@apollo/client/react/hooks";
import { ApolloClient } from "@apollo/client";
import { SearchNodeData, SearchNode, NodeId } from "../../../../../common/types";
import { SEARCH_NODES } from "../queries";
import HierarchySearchDropdown from "../HierarchySearch/HierarchySearchDropdown";
import { ActionsSection, ContentSection, NameInput } from "./shared-components";
import { ActionButton, ButtonContainer } from "../../../../../common/components";

export interface LinkNodeDialogProps {
  onLink: (inputNode: NodeId | undefined, newNode: string | undefined) => void;
  onCancel: () => void;
  isProject: boolean;
}

enum DialogTab {
  Move,
  Create,
}

const onSearchChanged = async (
  input: string,
  setSearchData: React.Dispatch<React.SetStateAction<SearchNodeData | undefined>>,
  client: ApolloClient<Record<string, unknown>>
) => {
  const arrayedInput = input.trim().split(" ");
  if (arrayedInput.length > 0) {
    const { data } = await client
      .query({
        query: SEARCH_NODES,
        variables: { searchQuery: arrayedInput, num: 10 },
        fetchPolicy: "network-only",
      })
      .catch(e => {
        return e;
      });
    if (data) {
      setSearchData(data as SearchNodeData);
    }
  }
};

const resetSearch = (
  setSearchInput: React.Dispatch<React.SetStateAction<string>>,
  setSearchData: React.Dispatch<React.SetStateAction<SearchNodeData | undefined>>,
  selectedNode?: SearchNode
) => {
  setSearchInput(selectedNode ? selectedNode.id.toString() : "");
  setSearchData(undefined);
};

function LinkNodeDialog(props: LinkNodeDialogProps): React.ReactElement {
  const { onCancel, onLink, isProject } = props;
  const [inputNode, setInputNode] = useState("");
  const [searchData, setSearchData] = useState<SearchNodeData | undefined>(undefined);
  const [selectedNode, setSelectedNode] = useState<SearchNode | undefined>(undefined);
  const [selectedTab, setSelectedTab] = useState<DialogTab>(DialogTab.Move);
  const client = useApolloClient() as ApolloClient<Record<string, unknown>>;
  const timeout = useRef<number>();

  const moving = selectedTab === DialogTab.Move;

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    clearTimeout(timeout.current);
    if (moving && inputNode.length > 0 && (!selectedNode || inputNode !== `${selectedNode.description}`)) {
      timeout.current = setTimeout(() => {
        onSearchChanged(inputNode, setSearchData, client);
      }, 500);
    } else if (inputNode.length === 0) {
      setSearchData(undefined);
    }
  }, [inputNode, client, selectedNode, moving]);

  useEffect(() => {
    if (selectedNode) {
      setInputNode(`${selectedNode.description}`);
    }
  }, [selectedNode]);

  const actionDisabled =
    (isProject && ((moving && selectedNode === undefined) || (!moving && !inputNode.length))) ||
    (!isProject && !moving && !inputNode.length);

  return (
    <FullscreenWrapper>
      <Container>
        <DialogTabs selectedTab={selectedTab} setTab={setSelectedTab} />
        <ContentContainer>
          <TitleContainer>
            {isProject
              ? moving
                ? "Move project"
                : "Move project into a new hierarchy"
              : moving
              ? "Link node"
              : "Move node into a new hierarchy"}
          </TitleContainer>
          <SubtitleContainer>
            {isProject
              ? moving
                ? "Search for nodes to move project."
                : "Create a new hierarchy and add the project into it."
              : moving
              ? "Search for nodes or leave empty to unlink the node from hierarchies."
              : "Create a new hierarchy and add the node into it."}
          </SubtitleContainer>
          <ContentSection>
            <NameInput
              type="text"
              value={inputNode}
              onChange={event => {
                const value = event.target.value;
                setInputNode(value);
              }}
              placeholder={moving ? "Search for nodes..." : "New hierarchy name..."}
            />
          </ContentSection>
          <ContentSection>
            {searchData && (
              <HierarchySearchDropdown
                searchNodes={searchData.searchNodes.nodes}
                resetSearch={() => resetSearch(setInputNode, setSearchData, selectedNode)}
                onResultSelected={node => setSelectedNode(node)}
                moreAvailable={searchData.searchNodes.moreResultsAvailable}
              />
            )}
          </ContentSection>
          <ActionsSection>
            <ButtonContainer disabledSave={actionDisabled}>
              <ActionButton
                onClick={() => onLink(selectedNode ? selectedNode.id : undefined, !moving ? inputNode : undefined)}
              >
                {isProject
                  ? moving
                    ? "Move project"
                    : "Create hierarchy"
                  : moving
                  ? !inputNode.length
                    ? "Unlink node"
                    : "Link node"
                  : "Create hierarchy"}
              </ActionButton>
              <ActionButton
                onClick={() => {
                  setInputNode("");
                  setSelectedNode(undefined);
                  onCancel();
                }}
              >
                Cancel
              </ActionButton>
            </ButtonContainer>
          </ActionsSection>
        </ContentContainer>
      </Container>
    </FullscreenWrapper>
  );
}

export interface DialogTabsProps {
  selectedTab: DialogTab;
  setTab: React.Dispatch<React.SetStateAction<DialogTab>>;
}

const DialogTabs = (props: DialogTabsProps): React.ReactElement => {
  const { selectedTab, setTab } = props;
  return (
    <TabContainer>
      <Tab selected={selectedTab === DialogTab.Move} onClick={() => setTab(DialogTab.Move)}>
        Move
      </Tab>
      <Tab selected={selectedTab === DialogTab.Create} onClick={() => setTab(DialogTab.Create)}>
        New hierarchy
      </Tab>
    </TabContainer>
  );
};

export default LinkNodeDialog;

const FullscreenWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
  background: ${greyTransparent};
  overflow-y: hidden;
`;

const Container = styled.div`
  flex-direction: column;
  width: 675px;
`;

const ContentContainer = styled.div`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  border: 1px solid ${valmetGreyBorder};
  z-index: 1500;
  font-size: 24px;
  color: ${defaultGrey};
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
  button:hover {
    background: ${filterGreen};
  }
`;

const TitleContainer = styled.div``;

const SubtitleContainer = styled.div`
  font-size: 14px;
  margin-top: 10px;
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Tab = styled.button<{ selected?: boolean }>`
  display: flex;
  width: 80px;
  height:30px
  align-items: center;
  justify-content: center;
  font-size: 9px;
  font-weight: 600;
  color: ${settingGreen};
  padding: 0;
  cursor: pointer;
  outline: none;
  background-color:  ${({ selected }) => (selected ? `${filterGreen}` : `white`)}
  border: 0;
  border-right: 1px solid ${settingGreen};
`;
