import produce from "immer";
import { cloneDeep, filter } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { useApolloClient, useMutation, useQuery } from "@apollo/client/react/hooks";
import { ApolloClient } from "@apollo/client";
import {
  endRecognitionsEditing,
  endRecognitionsMassFreeze,
  initializeRecognitionsEditState,
  initializeRecognitionsEditType,
  initializeRecognitionsWaitingForReady,
  recognitionsAddFreezing,
  recognitionsCopyCurrent,
  recognitionsEditCalculate,
  recognitionsEditChange,
  recognitionsHasError,
  recognitionsRemoveFreezing,
  startRecognitionsMassFreeze,
} from "../../../../actions/recognitionsEditActions";
import { AVAILABLE_RECOGNITION_COLUMNS } from "../../../../common/columns";
import { RECOGNITION_EDIT_COLUMNS, RecPlanEstRecEditModes } from "./recognitionsEditingSetting";
import { parsePeriod, Period, periodQuarterString, periodYearString } from "../../../../common/period";
import {
  AccessRight,
  AppState,
  Comment,
  CommentType,
  HierarchyItemEnum,
  ProjectId,
  UserProjectAccess,
} from "../../../../common/types";
import {
  ByPeriodRecognition,
  EditableRecognitionRow,
  ProjectRecognitionsEditResultData,
  ProjectRecognitionsResult,
  ProjectRecognitionsResultData,
  RateCurrencyPair,
  RecognitionEditType,
  RecognitionPlanChange,
  RecognitionPlanSaveResult,
  RecognitionsEditStateChange,
} from "./types";
import { ColumnId, EditableColumnId, GeneratedColumnId } from "../../../../common/columnsTypes";
import MassFreezeDialog from "./MassFreezeDialog";
import { ProjectRecognitionsEditTable, ProjectRecognitionsTable } from "./ProjectRecognitionsTable";
import {
  addRecognitionPlanComment,
  deleteRecognitionPlanComment,
  GET_COMMENT_TYPES,
  GET_PROJECTS_RECOGNITIONS,
  GET_PROJECTS_RECOGNITIONS_FOR_EDITING,
  pollForRecPlanSaveReady,
  SAVE_RECOGNITIONS,
} from "./queries";
import LoadingView from "../../../LoadingView";
import ColumnSetSelection from "./ColumnSetSelection";
import { ProjectHeaderMinimizedHeight, RecTabHeaderMinimizedHeight } from "../constants";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import CreateProjectActionsHeader from "../../../ProjectHeader/CreateProjectActionsHeader";
import { cancelNewProject } from "../../../../actions/projectActions";
import { resetProjectHierarchy } from "../../../../actions/hierarchyActions";
import { RouteComponentProps, withRouter } from "react-router-dom";
import routes from "../../../../common/routes";
import CreateProjectErrorsBox from "../../../ProjectHeader/CreateProjectErrorsBox";
import ErrorBox from "../../../ErrorBox";

export interface ProjectRecognitionsProps {
  projectId: ProjectId;
  itemType: HierarchyItemEnum;
  projectCreationMode: boolean;
  access: UserProjectAccess;
  onSaveSuccess: () => void;
}

export interface ProjectRecognitionTableProps {
  projectId: number;
  itemType: HierarchyItemEnum;
  projectCreationMode: boolean;
  commentTypes: CommentType[];
  hidePreOBL: boolean;
  projectRecAsFlat: boolean;
  onSaveSuccess: () => void;
}

const mapStateToProps = (state: AppState) => {
  const isEstimateEditable = state.recognitionEditState.options.editProjectType !== RecognitionEditType.LnServiceOrder;
  const userPreference = state.userPreferencesState.estRecEditMode;
  return {
    selectedColumnSet: state.columnSetState.recognitionColumnSet,
    selectedCurrency: state.settingsState.selectedCurrency,

    editing: state.recognitionEditState.editing,
    waitingForReady: state.recognitionEditState.waitingForReady,
    editRecognitionsInitialized: state.recognitionEditState.initialized,
    editRecognitions: state.recognitionEditState.recognitions,
    changes: state.recognitionEditState.changes,
    options: state.recognitionEditState.options,
    selectedEstRecEditMode: isEstimateEditable ? userPreference : RecPlanEstRecEditModes.WipCosts,

    massFreeze: state.recognitionEditState.massFreeze,
    projectCreationErrors: state.recognitionEditState.recognitionsErrors.length > 0,
    nodeHierarchyApplicationModifiedDateTime: state.projectState.nodeHierarchyApplicationModifiedDateTime,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onLoadedRecognitionsView: (recognitions: ProjectRecognitionsResult) => {
    dispatch(initializeRecognitionsEditType(recognitions.editType));
    dispatch(initializeRecognitionsWaitingForReady(recognitions.id));
  },
  onLoadedRecognitions: (recognitions: ProjectRecognitionsEditResultData) => {
    dispatch(initializeRecognitionsEditState(recognitions));
    dispatch(
      initializeRecognitionsWaitingForReady(recognitions.projectRecognitions && recognitions.projectRecognitions.id)
    );
  },
  onEditRecognitions: (period: Period, columnId: EditableColumnId, field: string, value: number) =>
    dispatch(recognitionsEditChange(period, columnId, field, value)),
  onCopyCurrent: (
    period: Period,
    columnId: EditableColumnId,
    field: string,
    value: number,
    estRecEditMode: RecPlanEstRecEditModes
  ) => dispatch(recognitionsCopyCurrent(period, columnId, field, value, estRecEditMode)),
  onEditCalculate: (estRecEditMode: RecPlanEstRecEditModes) => dispatch(recognitionsEditCalculate(estRecEditMode)),
  onResetRecognitionsEdit: () => dispatch(endRecognitionsEditing),
  onAddFreezing: (
    period: Period,
    columnId: EditableColumnId,
    field: string,
    value: number,
    comment: string,
    estRecEditMode: RecPlanEstRecEditModes
  ) => dispatch(recognitionsAddFreezing(period, columnId, field, value, comment, estRecEditMode)),
  onRemoveFreezing: (period: Period, columnId: EditableColumnId, field: string) =>
    dispatch(recognitionsRemoveFreezing(period, columnId, field)),
  onRecognitionHasError: (id: string, hasError: boolean) => dispatch(recognitionsHasError(id, hasError)),
  openMassFreezeDialog: (from: Period, to: Period) => dispatch(startRecognitionsMassFreeze(from, to)),
  closeMassFreezeDialog: () => dispatch(endRecognitionsMassFreeze()),
  cancelNewProject: () => {
    dispatch(cancelNewProject());
    dispatch(resetProjectHierarchy());
  },
});

function ProjectRecognitions(
  props: ProjectRecognitionsProps &
    RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>
): React.ReactElement {
  // Reset recognitions edit on mount
  const { editing, onResetRecognitionsEdit, projectCreationMode, itemType, projectId, access } = props;
  useEffect(() => {
    if (!projectCreationMode) {
      onResetRecognitionsEdit();
    }
  }, [onResetRecognitionsEdit, projectCreationMode]);

  const { data: commentTypesResult } = useQuery<{ commentTypes: CommentType[] }>(GET_COMMENT_TYPES);
  const commentTypes = commentTypesResult ? commentTypesResult.commentTypes : [];
  const userCanEdit = access.has(AccessRight.EditRecognitions);

  const [hidePreOBL, setHidePreOBL] = useLocalStorage<boolean>("hidePreOBL", true);
  const [projectRecAsFlat, setProjectRecAsFlat] = useLocalStorage("projectRecAsFlat", false);

  return (
    <>
      {itemType !== HierarchyItemEnum.NewProject && (
        <RecButtonsContainer>
          <ColumnSetSelection
            itemType={itemType}
            projectId={itemType !== HierarchyItemEnum.Node ? projectId : null}
            userCanEdit={itemType !== HierarchyItemEnum.Node && userCanEdit}
            hidePreOBL={hidePreOBL}
            setHidePreOBL={value => setHidePreOBL(value)}
            projectRecAsFlat={projectRecAsFlat}
            setProjectRecAsFlat={setProjectRecAsFlat}
          />
        </RecButtonsContainer>
      )}
      {editing || projectCreationMode ? (
        <ProjectRecognitionsEdit
          {...props}
          commentTypes={commentTypes}
          hidePreOBL={hidePreOBL}
          projectRecAsFlat={projectRecAsFlat}
        />
      ) : (
        <ProjectRecognitionsView
          {...props}
          commentTypes={commentTypes}
          hidePreOBL={hidePreOBL}
          projectRecAsFlat={projectRecAsFlat}
        />
      )}
    </>
  );
}

const availableColumnIds = AVAILABLE_RECOGNITION_COLUMNS.map(c => c.id) as (ColumnId | GeneratedColumnId)[];

interface ProjectPeriods {
  asSoldPeriod: Period | null;
  budgetPeriod: Period | null;
  oblPeriod: Period | null;
  completePeriod: Period | null;
}

function ProjectRecognitionsView(
  props: ProjectRecognitionTableProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {
  const client = useApolloClient() as ApolloClient<Record<string, unknown>>;
  const {
    projectId,
    selectedColumnSet,
    selectedCurrency,
    itemType,
    onLoadedRecognitionsView,
    commentTypes,
    hidePreOBL,
    projectRecAsFlat,
    waitingForReady,
  } = props;
  const selectedColumns = filter(selectedColumnSet.columns, column => availableColumnIds.includes(column.id));

  const [recognitions, setRecognitions] = useState<ByPeriodRecognition[] | "no_data" | "loading" | "error">("loading");
  const [editType, setEditType] = useState<RecognitionEditType>(RecognitionEditType.NonEditable);
  const [projectPeriods, setProjectPeriods] = useState<ProjectPeriods>();
  const [currencyPairs, setCurrencyPairs] = useState<RateCurrencyPair[] | undefined>(undefined);

  const columns = selectedColumns.map(column => column.id);
  const { error } = useQuery<ProjectRecognitionsResultData>(GET_PROJECTS_RECOGNITIONS, {
    variables: {
      id: projectId,
      columns: columns,
      currencyId: selectedCurrency ? selectedCurrency.id : undefined,
      itemType: itemType === HierarchyItemEnum.NewProject ? HierarchyItemEnum.Project : itemType,
    },
    fetchPolicy: "no-cache",
    skip: !selectedCurrency || itemType === HierarchyItemEnum.NewProject || waitingForReady != null,
    onCompleted: data => {
      if (data) {
        const recs = data.projectRecognitions;
        const asSoldPeriod = recs && recs.asSoldPeriod ? parsePeriod(recs.asSoldPeriod) : null;
        const budgetPeriod = recs && recs.budgetPeriod ? parsePeriod(recs.budgetPeriod) : null;
        const oblPeriod = recs && recs.oblPeriod ? parsePeriod(recs.oblPeriod) : null;
        const completePeriod = recs && recs.completePeriod ? parsePeriod(recs.completePeriod) : null;
        setProjectPeriods({ asSoldPeriod, budgetPeriod, oblPeriod, completePeriod });
        setRecognitions(recs && recs.grouped.length > 0 ? recs.grouped : "no_data");
        if (recs) {
          setCurrencyPairs(recs.rateCurrencyPairs || undefined);
          onLoadedRecognitionsView(recs);
          setEditType(recs.editType);
        }
      }
    },
    onError: () => setRecognitions("error"),
  });

  const onDeleteComment = (recognitions: ByPeriodRecognition[]) => async (
    period: Period,
    column: string,
    field: string,
    type: string,
    value: number,
    content: string
  ): Promise<void> => {
    const monthString = `${period.year}-${period.month}`;

    if (selectedCurrency === undefined) {
      console.log("Deleting comment failed. Currency not selected");
      return;
    }

    const [result, errors] = await deleteRecognitionPlanComment(client, projectId, selectedCurrency.id, {
      period: monthString,
      column,
      field,
      type,
      value,
      content,
    });

    const removeComment = (type: string) =>
      setRecognitions(
        produce(recognitions, nextRecognitions => {
          const yearString = periodYearString(period);
          const year = nextRecognitions.find(v => v.period === yearString);
          if (!year || !year.subGroups) {
            console.error(`Deleting comment failed. Couldn't find year row ${yearString}`);
            return;
          }

          const quarterString = periodQuarterString(period);
          const quarter = year.subGroups.find(v => v.period === quarterString);
          if (!quarter || !quarter.subGroups) {
            console.error(`Deleting comment failed. Couldn't find quarter row ${quarterString}`);
            return;
          }

          const month = quarter.subGroups.find(v => v.period === monthString);
          if (!month) {
            console.error(`Deleting comment failed. Couldn't find month row ${monthString}`);
            return;
          }

          const cell = month.columns.find(v => v.columnId === column);
          if (!cell) {
            console.error(`Deleting comment failed. Couldn't find cell ${monthString} ${column}`);
            return;
          }

          const commentsForField = cell.comments.find(v => v.fieldName === field);
          if (result && commentsForField) {
            commentsForField.comments = commentsForField.comments.filter(v => v.type !== type);
          }
        })
      );

    if (errors) console.error(`Deleting comment failed ${errors}`);
    else if (result) removeComment(type);
    else console.error(`Deleting comment failed. Invalid response ${result}`);
  };

  const onAddComment = (recognitions: ByPeriodRecognition[]) => async (
    period: Period,
    column: string,
    field: string,
    type: string,
    value: number,
    content: string
  ): Promise<Comment> => {
    const monthString = `${period.year}-${period.month}`;

    console.log(`Add comment ${monthString} ${field} ${type} ${content} `);

    if (selectedCurrency === undefined) {
      console.log("Saving comment failed. Currency not selected");
      return Promise.reject("Currency not selected");
    }

    const [result, error] = await addRecognitionPlanComment(client, projectId, selectedCurrency.id, {
      period: monthString,
      column,
      field,
      type,
      value,
      content,
    });

    const insertComment = (c: Comment) =>
      setRecognitions(
        produce(recognitions, nextRecognitions => {
          const yearString = periodYearString(period);
          const year = nextRecognitions.find(v => v.period === yearString);
          if (!year || !year.subGroups) {
            console.error(`Saving comment failed. Couldn't find year row ${yearString}`);
            return;
          }

          const quarterString = periodQuarterString(period);
          const quarter = year.subGroups.find(v => v.period === quarterString);
          if (!quarter || !quarter.subGroups) {
            console.error(`Saving comment failed. Couldn't find quarter row ${quarterString}`);
            return;
          }

          const month = quarter.subGroups.find(v => v.period === monthString);
          if (!month) {
            console.error(`Saving comment failed. Couldn't find month row ${monthString}`);
            return;
          }

          const cell = month.columns.find(v => v.columnId === column);
          if (!cell) {
            console.error(`Saving comment failed. Couldn't find cell ${monthString} ${column}`);
            return;
          }

          const commentsForField = cell.comments.find(v => v.fieldName === field);

          if (commentsForField) {
            commentsForField.comments = commentsForField.comments.filter(v => v.type !== c.type);
          }

          if (commentsForField) commentsForField.comments.push(c);
          else cell.comments.push({ fieldName: field, comments: [c] });
        })
      );

    if (error) {
      console.error(`Saving comment failed ${error}`);
      return Promise.reject(error);
    } else if (result && result.errors) {
      console.error(`Saving comment failed ${result.errors}`);
      return Promise.reject(result.errors);
    } else if (result && result.comment) {
      insertComment({ ...result.comment, type });
      return Promise.resolve(result.comment);
    } else {
      console.error(`Saving comment failed. Invalid response ${result}`);
      return Promise.reject("Saving comment failed. Invalid response");
    }
  };

  const oblPeriod = projectPeriods ? projectPeriods.oblPeriod : null;

  const filterPreOBLForMonths = (monthRecs: ByPeriodRecognition[], oblPeriod: Period): ByPeriodRecognition[] => {
    return monthRecs.filter(monthRec => parseInt(monthRec.period.split("-")[1]) >= oblPeriod.month);
  };

  const filterPreOBLForQuarters = (quarterRecs: ByPeriodRecognition[], oblPeriod: Period): ByPeriodRecognition[] => {
    const oblQuarter = Math.ceil(oblPeriod.month / 3);
    return quarterRecs
      .filter(quarterRec => parseInt(quarterRec.period.charAt(1)) >= oblQuarter)
      .map(quarterRec => {
        if (parseInt(quarterRec.period.charAt(1)) != oblQuarter) return quarterRec;
        else {
          const newRec = cloneDeep(quarterRec);
          if (newRec.subGroups !== undefined) {
            newRec.subGroups = filterPreOBLForMonths(newRec.subGroups, oblPeriod);
          }
          return newRec;
        }
      });
  };

  const filterPreOBL = (completeRec: ByPeriodRecognition[]): ByPeriodRecognition[] => {
    if (hidePreOBL && oblPeriod)
      return completeRec
        .filter(yearRec => parseInt(yearRec.period) >= oblPeriod.year)
        .map(yearRec => {
          if (parseInt(yearRec.period) > oblPeriod.year) return yearRec;
          else {
            const newRec = cloneDeep(yearRec);
            if (newRec.subGroups !== undefined) {
              newRec.subGroups = filterPreOBLForQuarters(newRec.subGroups, oblPeriod);
            }
            return newRec;
          }
        });
    else return completeRec;
  };

  const getRenderData = (): ByPeriodRecognition[] | string => {
    if (!selectedCurrency) return "Error: Currency not selected";
    else if (recognitions === "loading") return "Loading...";
    else if (recognitions === "no_data") return "No data found";
    else if (recognitions === "error") return `Error: ${error ? error.message : "Unknown error"}`;
    else return filterPreOBL(recognitions);
  };

  const renderData = getRenderData();

  return (
    <Container>
      {recognitions === "loading" ? (
        <LoadingView />
      ) : typeof renderData === "string" ? (
        <InfoContainer>{renderData}</InfoContainer>
      ) : (
        <ContentContainer>
          <ProjectRecognitionsTable
            selectedColumns={selectedColumns}
            oblPeriod={oblPeriod}
            completePeriod={projectPeriods ? projectPeriods.completePeriod : null}
            recognitions={{ value: renderData, editType }}
            projectRecAsFlat={projectRecAsFlat}
            onAddComment={onAddComment(renderData)}
            onDeleteComment={onDeleteComment(renderData)}
            commentTypes={commentTypes}
            currencyPairs={currencyPairs}
          />
        </ContentContainer>
      )}
    </Container>
  );
}

function ProjectRecognitionsEdit(
  props: ProjectRecognitionTableProps &
    RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>
): React.ReactElement {
  const {
    projectId,
    selectedCurrency,
    editRecognitionsInitialized,
    itemType,
    editRecognitions,
    options,
    onLoadedRecognitions,
    onEditRecognitions,
    onCopyCurrent,
    onEditCalculate,
    onRecognitionHasError,
    onAddFreezing,
    onRemoveFreezing,
    closeMassFreezeDialog,
    massFreeze,
    changes,
    commentTypes,
    hidePreOBL,
    waitingForReady,
    projectCreationErrors,
    history,
    cancelNewProject,
    onSaveSuccess,
    selectedEstRecEditMode,
  } = props;

  const [currencyPairs, setCurrencyPairs] = useState<RateCurrencyPair[] | undefined>(undefined);

  const [startedPolling] = useState(new Date().getTime());

  useEffect(() => {
    if (massFreeze.dialogOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "initial";
    }
  }, [massFreeze.dialogOpen]);

  const { loading, error, data, stopPolling } = useQuery<ProjectRecognitionsEditResultData>(
    GET_PROJECTS_RECOGNITIONS_FOR_EDITING,
    {
      variables: {
        id: projectId,
        projectId,
        columns: RECOGNITION_EDIT_COLUMNS.allNonCalculatedColumnIds,
        currencyId: selectedCurrency && selectedCurrency.id,
        itemType: itemType === HierarchyItemEnum.NewProject ? HierarchyItemEnum.Project : itemType,
      },
      skip: selectedCurrency === undefined || waitingForReady != null,
      fetchPolicy: "no-cache",
      onCompleted: data => {
        if (data) {
          setCurrencyPairs(data.projectRecognitions?.rateCurrencyPairs || undefined);
          onLoadedRecognitions(data);
        }
      },
      pollInterval: itemType === HierarchyItemEnum.NewProject ? 1000 : 0,
    }
  );

  const filterPreOBL = (completeRecs: EditableRecognitionRow[], oblPeriod: Period) => {
    return completeRecs.filter(
      rec =>
        rec.period.year > oblPeriod.year || (rec.period.year === oblPeriod.year && rec.period.month >= oblPeriod.month)
    );
  };
  const rows = hidePreOBL && options.oblPeriod ? filterPreOBL(editRecognitions, options.oblPeriod) : editRecognitions;
  const isLoading = (loading || editRecognitionsInitialized) && !error;

  const pollTimedOut = new Date().getTime() - startedPolling >= 60000;
  if ((data && data.projectRecognitions) || pollTimedOut) {
    stopPolling();
  }

  const [saving, setSaving] = useState<boolean>(false);
  const [saveResultErrors, setSaveResultErrors] = useState<string[] | undefined>(undefined);
  const onNewProjectRecSave = (projectId: number, currencyId: number) => {
    const filteredChanges = Array.from(changes.values()).filter(
      v => !RECOGNITION_EDIT_COLUMNS.isCalculatedColumn(v.columnId, v.field)
    );
    setSaveResultErrors(undefined);
    setSaving(true);

    if (filteredChanges.length === 0) {
      setSaving(false);
      onSaveSuccess();
    } else {
      saveRecPlan(projectId, currencyId, filteredChanges);
    }
  };

  const [saveRecognitions] = useMutation<{
    saveRecognitionPlan: RecognitionPlanSaveResult;
  }>(SAVE_RECOGNITIONS);

  const saveRecPlan = useCallback(
    (projectId: number, currencyId: number, changes: RecognitionsEditStateChange[]) => {
      saveRecognitions({
        variables: {
          projectId,
          changes: changes.map(
            c =>
              ({
                period: `${c.period.year}-${c.period.month}`,
                column: c.columnId,
                field: c.field,
                value: c.value,
                freezing: c.freezingComment ? { comment: c.freezingComment } : undefined,
              } as RecognitionPlanChange)
          ),
        },
      }).then(res => {
        if (res && res.data && res.data.saveRecognitionPlan.applicationModifiedDateTime) {
          pollForRecPlanReady(projectId, res.data.saveRecognitionPlan.applicationModifiedDateTime);
        } else {
          const errorMessages =
            !res || !res.data
              ? ["No response."]
              : !res.data.saveRecognitionPlan.applicationModifiedDateTime
              ? ["Missing applicationModifiedDateTime"]
              : res.data && res.data.saveRecognitionPlan.error
              ? [res.data.saveRecognitionPlan.error]
              : undefined;
          errorMessages && setSaveResultErrors(errorMessages);
          setSaving(false);
        }
      });
    },
    [saveRecognitions]
  );

  const {
    pollForReady: pollForRecPlanReady,
    ready: recPlanSaveReady,
    loading: pollingForRecPlanSaveReady,
  } = pollForRecPlanSaveReady();

  useEffect(() => {
    if (!pollingForRecPlanSaveReady && !!recPlanSaveReady) {
      setSaving(false);
      onSaveSuccess();
    }
  }, [recPlanSaveReady, pollingForRecPlanSaveReady]);

  const cancelCreateProject = () => {
    cancelNewProject();
    history.push(routes.MAIN);
  };

  return (
    <Container>
      {itemType === HierarchyItemEnum.NewProject && (
        <CreateProjectActionsHeader
          hasErrors={projectCreationErrors}
          saving={saving || pollingForRecPlanSaveReady}
          readyToSave={!!selectedCurrency}
          finalTab={true}
          onClickContinue={() => selectedCurrency && onNewProjectRecSave(projectId, selectedCurrency.id)}
          onClickCancel={cancelCreateProject}
        />
      )}
      {saveResultErrors !== undefined && saveResultErrors.length > 0 && (
        <CreateProjectErrorsBox errors={saveResultErrors} />
      )}
      {isLoading && <LoadingView />}
      {(error || !selectedCurrency) && (
        <InfoContainer>Error: {!selectedCurrency ? "Currency not selected." : error?.message}</InfoContainer>
      )}
      {!isLoading && !error && (
        <ContentContainer>
          {rows.length > 0 ? (
            <ProjectRecognitionsEditTable
              selectedColumns={RECOGNITION_EDIT_COLUMNS.allColumns}
              recognitions={{
                value: rows,
                options,
              }}
              changes={changes}
              projectRecAsFlat={false}
              onValueChange={onEditRecognitions}
              onCopyCurrent={(period, columnId, field, value) =>
                onCopyCurrent(period, columnId, field, value, selectedEstRecEditMode)
              }
              onFieldLeave={_ => onEditCalculate(selectedEstRecEditMode)}
              onAddFreezing={(period, columnId, field, value, comment) =>
                onAddFreezing(period, columnId, field, value, comment, selectedEstRecEditMode)
              }
              onRemoveFreezing={onRemoveFreezing}
              onHasError={onRecognitionHasError}
              commentTypes={commentTypes}
              selectedEstRecEditMode={selectedEstRecEditMode}
              currencyPairs={currencyPairs}
            />
          ) : itemType === HierarchyItemEnum.NewProject && !pollTimedOut ? (
            <LoadingView />
          ) : itemType === HierarchyItemEnum.NewProject ? (
            <ErrorBox
              caption={"No data found for project recognitions yet."}
              errorText={
                "You could continue saving this project and edit its recognitions plan in the project card later."
              }
            />
          ) : (
            "No data found"
          )}
          {massFreeze.dialogOpen && (
            <MassFreezeDialog
              rows={rows}
              fromPeriod={massFreeze.fromPeriod}
              toPeriod={massFreeze.toPeriod}
              onAddFreezing={(period, columnId, fieldId, value, comment) =>
                onAddFreezing(period, columnId, fieldId, value, comment, selectedEstRecEditMode)
              }
              onClose={() => closeMassFreezeDialog()}
            />
          )}
        </ContentContainer>
      )}
    </Container>
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectRecognitions));

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 0px;
  padding-bottom: 68px;
  min-height: calc(100vh - 100px - 134px);
`;

const RecButtonsContainer = styled.div`
  position: relative;
  align-self: flex-start;
  width: 100vw;
  position: sticky;
  top: ${ProjectHeaderMinimizedHeight};
  left: 0;
  z-index: 100;
  min-height: ${RecTabHeaderMinimizedHeight};
  background: #ffffff;
`;

const ContentContainer = styled.div`
  align-self: center;
  max-width: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
