import React from "react";
import styled from "styled-components";
import Notifications from "./Notifications";
import MyProjects from "./MyProjects";
import EstimationSchedule from "./EstimationSchedule";

function Main(): React.ReactElement {
  return (
    <Container>
      <Content>
        <Header>
          <Title>Valmet</Title>
          <Subtitle>Project and Order Planning</Subtitle>
        </Header>
        <Fluid>
          <Columns>
            <MyProjects />
            <Notifications />
          </Columns>
          <Columns>
            <EstimationSchedule />
          </Columns>
        </Fluid>
      </Content>
    </Container>
  );
}

export default Main;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  /*justify-content: space-between;*/
`;

const Title = styled.h1`
  margin-right: 32px;
`;
const Subtitle = styled.h2``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Fluid = styled.div`
  display: flex;
  column-gap: 15px;

  @media (min-width: 1400px) {
    flex-direction: row;
  }
  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;

const Columns = styled.div`
  display: flex;
  column-gap: 15px;

  @media (min-width: 1000px) {
    flex-direction: row;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
