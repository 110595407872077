import React from "react";
import styled from "styled-components";

import { defaultGrey, projectDetailsYellow, valmetGreyBorder } from "../../../../common/colors";

interface SectionProps {
  header: string;
  loadingErrors?: string[];
}

function CreateProjectSection(props: React.PropsWithChildren<SectionProps>) {
  const { children, header, loadingErrors } = props;

  return (
    <ContainerContainer>
      <Container>
        <HeaderContainer>{header}</HeaderContainer>
        {loadingErrors && loadingErrors.length && (
          <LoadingContainer>
            <ErrorTitleText>{`Error loading data: `}</ErrorTitleText>
            {loadingErrors.map((error, i) => (
              <div key={i}>{error.toString()}</div>
            ))}
          </LoadingContainer>
        )}
        <ContentContainer>{children}</ContentContainer>
      </Container>
    </ContainerContainer>
  );
}

export default CreateProjectSection;

const ContainerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px ${valmetGreyBorder};
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  -moz-page-break-inside: avoid;
  /*page-break-inside: avoid;*/
  break-inside: avoid-column;
  margin-bottom: 20px;
  flex: 1;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${projectDetailsYellow};
  padding: 10px;
  color: ${defaultGrey};
  text-transform: uppercase;
  height: 24px;
`;

const ContentContainer = styled.div``;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const ErrorTitleText = styled.div`
  font-weight: bold;
`;
