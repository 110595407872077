import {
  AccessRight,
  HierarchyItem,
  HierarchyItemEnum,
  NodeId,
  ProjectId,
  UserProjectAccess,
} from "../../../common/types";
import React from "react";
import { Tabs } from "./Project";
import routes from "../../../common/routes";
import { TYPE_CREATE_PROJECT } from "../../../common/constants";
import ProjectOverview from "./ProjectOverview";
import CreateProjectBasicsView from "./ProjectBasics/CreateProjectBasicsView";
import ProjectBasicsView from "./ProjectBasics/ProjectBasicsView";
import ProjectHierarchy from "./ProjectHierarchy/ProjectHierarchy";
import ProjectRecognitions from "./ProjectRecognitions/ProjectRecognitions";
import ProjectCosts from "./ProjectCosts";
import ProjectLogsPanel from "./ProjectLogs";
import ProjectComments from "./Comments/ProjectComments";
import TotalComments from "./Comments/TotalComments";

export const readProjectIdFromPath = (itemType: HierarchyItemEnum): number | undefined => {
  const pathname = window.location.pathname;
  const readFromPath = (): string | undefined => {
    switch (itemType) {
      case HierarchyItemEnum.Project:
        return pathname.slice(routes.PROJECT.length + 1);
      case HierarchyItemEnum.Node:
        return pathname.slice(routes.NODE.length + 1);
      case HierarchyItemEnum.NewProject:
        return undefined;
    }
  };
  const projectId = readFromPath();
  if (projectId !== undefined && projectId.length === 0) {
    return undefined;
  } else {
    return projectId !== undefined ? parseInt(projectId) : projectId;
  }
};

export const readItemTypeFromPath = (): HierarchyItemEnum => {
  const pathname = window.location.pathname;
  if (pathname.includes(TYPE_CREATE_PROJECT)) {
    return HierarchyItemEnum.NewProject;
  } else if (pathname.includes("project")) {
    return HierarchyItemEnum.Project;
  } else {
    return HierarchyItemEnum.Node;
  }
};

export enum ComponentType {
  NoProjectAccess,
  NoTabAccess,
  AccessibleTab,
}

export interface RenderedTabContent {
  componentType: ComponentType;
  component: JSX.Element | null;
}

export const renderTabContent = (
  selectedTab: Tabs,
  itemType: HierarchyItemEnum,
  projectId: ProjectId | NodeId | undefined,
  access: UserProjectAccess,
  hierarchy: HierarchyItem | undefined,
  onSaveSuccess: {
    createProjectBasics: (applicationModifiedDateTime: string) => void;
    createProjectHierarchy: () => void;
    createProjectRec: () => void;
  }
): RenderedTabContent => {
  if (projectId === undefined && itemType !== HierarchyItemEnum.NewProject) {
    return { componentType: ComponentType.NoProjectAccess, component: null };
  } else {
    const id = projectId || 0;
    switch (itemType) {
      case HierarchyItemEnum.Project:
      case HierarchyItemEnum.NewProject:
        switch (selectedTab) {
          case Tabs.Overview:
            if (!access.hasCostsAccessOnly())
              return { componentType: ComponentType.AccessibleTab, component: <ProjectOverview projectId={id} /> };
            else return { componentType: ComponentType.NoTabAccess, component: null };
          case Tabs.Basics:
            if (itemType === HierarchyItemEnum.NewProject) {
              return {
                componentType: ComponentType.AccessibleTab,
                component: <CreateProjectBasicsView projectId={id} onSaveSuccess={onSaveSuccess.createProjectBasics} />,
              };
            } else if (access.has(AccessRight.ViewProjectCard)) {
              return {
                componentType: ComponentType.AccessibleTab,
                component: (
                  <ProjectBasicsView
                    projectId={id}
                    itemType={itemType}
                    hierarchy={hierarchy}
                    userCanEdit={access.has(AccessRight.EditProjectCard)}
                  />
                ),
              };
            } else {
              return { componentType: ComponentType.NoProjectAccess, component: null };
            }
          case Tabs.Hierarchy:
            return {
              componentType: ComponentType.AccessibleTab,
              component: <ProjectHierarchy onSaveSuccess={onSaveSuccess.createProjectHierarchy} />,
            };
          case Tabs.REC:
            if (access.has(AccessRight.ViewRecognitions)) {
              return {
                componentType: ComponentType.AccessibleTab,
                component: (
                  <ProjectRecognitions
                    projectId={id}
                    itemType={itemType}
                    projectCreationMode={itemType === HierarchyItemEnum.NewProject}
                    access={access}
                    onSaveSuccess={onSaveSuccess.createProjectRec}
                  />
                ),
              };
            } else {
              return { componentType: ComponentType.NoTabAccess, component: null };
            }
          case Tabs.Costs:
            //  const projectInactive =
            //    projectHeaderData &&
            //    projectHeaderData.projectPhaseId !== undefined &&
            //    projectHeaderData.projectPhaseId === 0 &&
            //    projectHeaderData.projectStatusId !== undefined &&
            //    projectHeaderData.projectStatusId === 0;
            if (access.has(AccessRight.ViewCosts)) {
              const userCanEdit = access.has(AccessRight.EditCosts); //&& !projectInactive;
              return {
                componentType: ComponentType.AccessibleTab,
                component: (
                  <ProjectCosts
                    projectId={id}
                    userCanEdit={userCanEdit}
                    hasCostsAccessOnly={access.hasCostsAccessOnly()}
                  />
                ),
              };
            } else {
              return { componentType: ComponentType.NoTabAccess, component: null };
            }
          case Tabs.Changelog:
            if (access.has(AccessRight.ViewProjectCard) && !access.hasCostsAccessOnly()) {
              return { componentType: ComponentType.AccessibleTab, component: <ProjectLogsPanel projectId={id} /> };
            } else {
              return { componentType: ComponentType.NoTabAccess, component: null };
            }
          case Tabs.Comments:
            if (access.has(AccessRight.ViewFinancialComments)) {
              return {
                componentType: ComponentType.AccessibleTab,
                component: <ProjectComments projectId={id} />,
              };
            } else {
              return { componentType: ComponentType.NoTabAccess, component: null };
            }
        }
        break;

      case HierarchyItemEnum.Node:
        switch (selectedTab) {
          case Tabs.Overview:
          case Tabs.Costs:
          case Tabs.Changelog:
          case Tabs.Hierarchy:
            return { componentType: ComponentType.NoTabAccess, component: null };
          case Tabs.Basics:
            return {
              componentType: ComponentType.AccessibleTab,
              component: (
                <ProjectBasicsView projectId={id} itemType={itemType} hierarchy={hierarchy} userCanEdit={false} />
              ),
            };
          case Tabs.REC:
            return {
              componentType: ComponentType.AccessibleTab,
              component: (
                <ProjectRecognitions
                  projectId={id}
                  itemType={itemType}
                  projectCreationMode={false}
                  access={access}
                  onSaveSuccess={() => undefined}
                />
              ),
            };
          case Tabs.Comments:
            return {
              componentType: ComponentType.AccessibleTab,
              component: <TotalComments nodeId={id} />,
            };
        }
    }
  }
};
