import gql from "graphql-tag";

// export const GET_PROJECT_REPORTS = gql`
//   query GetProjectReports($projectId: ProjectId!) {
//     projectReports(projectId: $projectId) {
//       token
//       url
//       id
//       pages {
//         id
//         description
//       }
//       description
//     }
//   }
// `;

export const GET_PROJECT_OVERVIEW_REPORT = gql`
  query GetProjectOverviewReport {
    projectOverviewReport {
      token
      tokenExpiration
      embedUrl
      datasetId
      reportId
      description
    }
  }
`;
