import gql from "graphql-tag";
import { ApolloClient } from "@apollo/client";
import { cloneDeep } from "lodash";

import { NodeId, PollReadyResult, ProjectId } from "../../../../common/types";
import {
  CommentPackage,
  CommentPackageInput,
  CommentPackageType,
  NodeCommentPackagesResult,
  ProjectCommentPackagesResult,
  PublishCommentPackageInput,
  SaveProjectCommentPackageResult,
} from "./types";
import { initLogger } from "../../../../logging";

const logger = initLogger(__filename);

export const GET_COMMENT_PACKAGES = gql`
  query ProjectCommentPacakges($projectId: ProjectId!) {
    projectCommentPackages(projectId: $projectId) {
      packages {
        ...CommentPackageFields
      }
      totalPackages {
        ...CommentPackageFields
      }
      subtotalPackages {
        ...CommentPackageFields
      }
      totalNodeName
      subtotalNodeName
      isMainProject
    }
  }
  fragment CommentPackageFields on CommentPackage {
    id
    period
    commentType
    content
    modified
    modifiedBy
    published
  }
`;

export const GET_NODE_COMMENT_PACKAGES = gql`
  query NodeCommentPacakges($nodeId: NodeId!) {
    nodeCommentPackages(nodeId: $nodeId) {
      projectId
      isTotal
      totalPackages {
        ...CommentPackageFields
      }
      subtotalPackages {
        ...CommentPackageFields
      }
    }
  }
  fragment CommentPackageFields on CommentPackage {
    id
    period
    commentType
    content
    modified
    modifiedBy
    published
  }
`;

export const GET_PERIOD_COMMENTS = gql`
  query ProjectPeriodComments($projectId: ProjectId!, $period: PeriodInput!) {
    projectPeriodComments(projectId: $projectId, period: $period) {
      recognitionPlanComments {
        period
        column
        field
        commentType
        value
        content
        currencyScenario
        createdBy
        createdDateTime
      }
      costEstimationComments {
        period
        commentId
        activityId
        activityCode
        commentType
        content
        createdBy
        createdDateTime
      }
    }
  }
`;

export const GET_TOTAL_PERIOD_COMMENTS = gql`
  query TotalPeriodComments($projectId: ProjectId!, $period: PeriodInput!) {
    totalPeriodComments(projectId: $projectId, period: $period) {
      comments {
        projectId
        description
        period
        content
        modifiedBy
        modified
        published
      }
    }
  }
`;

const SAVE_COMMENT_PACKAGE = gql`
  mutation SaveCommentPackage($projectId: ProjectId!, $commentPackage: CommentPackageInput!) {
    saveCommentPackage(projectId: $projectId, commentPackage: $commentPackage) {
      success
      commentPackage {
        id
        period
        commentType
        content
        modified
        modifiedBy
        published
      }
      error
      applicationModifiedDate
    }
  }
`;

const PUBLISH_COMMENT_PACKAGE = gql`
  mutation PublishCommentPackage($projectId: ProjectId!, $commentPackage: PublishCommentPackageInput!) {
    publishCommentPackage(projectId: $projectId, commentPackage: $commentPackage) {
      success
      commentPackage {
        id
        period
        commentType
        content
        modified
        modifiedBy
        published
      }
      error
      applicationModifiedDate
    }
  }
`;

const POLL_SAVE_READY = gql`
  query PollCommentPackageSaveReady($projectId: ProjectId!, $applicationModifiedDate: ISOTimestamp!) {
    pollCommentPackageSaveReady(projectId: $projectId, applicationModifiedDate: $applicationModifiedDate) {
      ready
    }
  }
`;

const delayAsync = <T>(thunk: () => Promise<T>, timeout: number): Promise<T> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        thunk()
          .then(result => resolve(result))
          .catch(reason => reject(reason));
      } catch (ex) {
        reject(ex);
      }
    }, timeout);
  });
};

const PollingTimeout = { error: "Operation did not finish in time" };

const poll = <T>(query: () => Promise<T | undefined>, retries: number): Promise<T> => {
  return query().then(result => {
    if (result !== undefined) {
      return Promise.resolve(result);
    } else if (retries > 0) {
      return delayAsync(() => poll(query, retries - 1), 1000);
    } else {
      return Promise.reject(PollingTimeout);
    }
  });
};

const updateCache = (
  projectId: ProjectId,
  nodeId: NodeId | undefined,
  pak: CommentPackage,
  client: ApolloClient<Record<string, unknown>>
) => {
  if (nodeId !== undefined) {
    // We are looking at the total comments for a node, so we need to update the cache for GET_NODE_COMMENT_PACKAGES
    const data = client.readQuery<{ nodeCommentPackages: NodeCommentPackagesResult }>({
      query: GET_NODE_COMMENT_PACKAGES,
      variables: {
        nodeId,
      },
    });
    if (data) {
      const newData = cloneDeep(data);
      if (pak.commentType === CommentPackageType.Total) {
        if (newData.nodeCommentPackages.totalPackages) {
          newData.nodeCommentPackages.totalPackages = newData.nodeCommentPackages.totalPackages.filter(
            p => p.id !== pak.id
          );
        } else {
          newData.nodeCommentPackages.totalPackages = [];
        }
        newData.nodeCommentPackages.totalPackages.push(pak);
      } else if (pak.commentType === CommentPackageType.Subtotal) {
        if (newData.nodeCommentPackages.subtotalPackages) {
          newData.nodeCommentPackages.subtotalPackages = newData.nodeCommentPackages.subtotalPackages.filter(
            p => p.id !== pak.id
          );
        } else {
          newData.nodeCommentPackages.subtotalPackages = [];
        }
        newData.nodeCommentPackages.subtotalPackages.push(pak);
      }
      client.writeQuery<{ nodeCommentPackages: NodeCommentPackagesResult }>({
        query: GET_NODE_COMMENT_PACKAGES,
        variables: {
          nodeId,
        },
        data: newData,
      });
    }
  } else {
    const data = client.readQuery<{ projectCommentPackages: ProjectCommentPackagesResult }>({
      query: GET_COMMENT_PACKAGES,
      variables: {
        projectId,
      },
    });
    if (data) {
      const newData = cloneDeep(data);
      if (pak.commentType === CommentPackageType.Project) {
        newData.projectCommentPackages.packages = newData.projectCommentPackages.packages.filter(p => p.id !== pak.id);
        newData.projectCommentPackages.packages.push(pak);
      } else if (pak.commentType === CommentPackageType.Total) {
        if (newData.projectCommentPackages.totalPackages) {
          newData.projectCommentPackages.totalPackages = newData.projectCommentPackages.totalPackages.filter(
            p => p.id !== pak.id
          );
        } else {
          newData.projectCommentPackages.totalPackages = [];
        }
        newData.projectCommentPackages.totalPackages.push(pak);
      } else if (pak.commentType === CommentPackageType.Subtotal) {
        if (newData.projectCommentPackages.subtotalPackages) {
          newData.projectCommentPackages.subtotalPackages = newData.projectCommentPackages.subtotalPackages.filter(
            p => p.id !== pak.id
          );
        } else {
          newData.projectCommentPackages.subtotalPackages = [];
        }
        newData.projectCommentPackages.subtotalPackages.push(pak);
      }
      client.writeQuery<{ projectCommentPackages: ProjectCommentPackagesResult }>({
        query: GET_COMMENT_PACKAGES,
        variables: {
          projectId,
        },
        data: newData,
      });
    }
  }
};

const saveCommentPackageImpl = async (
  projectId: ProjectId,
  commentPackage: CommentPackageInput,
  client: ApolloClient<Record<string, unknown>>
): Promise<SaveProjectCommentPackageResult> => {
  return client
    .mutate<{ saveCommentPackage: SaveProjectCommentPackageResult }>({
      mutation: SAVE_COMMENT_PACKAGE,
      variables: { projectId, commentPackage },
    })
    .then(result => {
      if (result.data) {
        if (result.data.saveCommentPackage.error === null) {
          return Promise.resolve(result.data.saveCommentPackage);
        } else {
          return Promise.reject(result.data.saveCommentPackage.error);
        }
      } else {
        return Promise.reject("No data!");
      }
    });
};

export const saveCommentPackage = (
  projectId: ProjectId,
  nodeId: NodeId | undefined,
  commentPackage: CommentPackageInput,
  client: ApolloClient<Record<string, unknown>>
) => {
  return saveCommentPackageImpl(projectId, commentPackage, client)
    .then(result => {
      return poll(() => {
        return client
          .query<{ pollCommentPackageSaveReady: PollReadyResult }>({
            query: POLL_SAVE_READY,
            variables: {
              projectId,
              applicationModifiedDate: result.applicationModifiedDate,
            },
            fetchPolicy: "no-cache",
          })
          .then(queryResult => {
            console.log("poll: ", queryResult.data.pollCommentPackageSaveReady.ready);
            if (queryResult.data.pollCommentPackageSaveReady.ready) {
              logger.debug("Save polling finished");
              return result;
            } else if (queryResult.errors) {
              logger.error("Save polling finished with errors:", queryResult.errors);
              return Promise.reject("Error while waiting save to finish");
            } else {
              return undefined; // keep polling
            }
          });
      }, 45);
    })
    .then(result => {
      const pak = result.commentPackage;
      if (pak) {
        updateCache(projectId, nodeId, pak, client);
      }
      return result;
    })
    .catch(reason => {
      if (reason === PollingTimeout) {
        logger.warn("Comment package save polling timeout for project", projectId);
        return Promise.reject(
          "Save was not finished in time. The save may still have been successful, but it took too long to wait for it to finish." +
            " Please contact support, if the save did not go through after waiting 5 minutes."
        );
      } else {
        logger.error("Comment package saving error for project", projectId, ":", reason);
        return Promise.reject(reason);
      }
    });
};

const publishCommentPackageImpl = async (
  projectId: ProjectId,
  commentPackage: PublishCommentPackageInput,
  client: ApolloClient<Record<string, unknown>>
): Promise<SaveProjectCommentPackageResult> => {
  return client
    .mutate<{ publishCommentPackage: SaveProjectCommentPackageResult }>({
      mutation: PUBLISH_COMMENT_PACKAGE,
      variables: { projectId, commentPackage },
    })
    .then(result => {
      if (result.data) {
        if (result.data.publishCommentPackage.error === null) {
          return Promise.resolve(result.data.publishCommentPackage);
        } else {
          return Promise.reject(result.data.publishCommentPackage.error);
        }
      } else {
        return Promise.reject("No data!");
      }
    });
};

export const publishCommentPackage = (
  projectId: ProjectId,
  nodeId: NodeId | undefined,
  commentPackage: PublishCommentPackageInput,
  client: ApolloClient<Record<string, unknown>>
) => {
  return publishCommentPackageImpl(projectId, commentPackage, client)
    .then(result => {
      return poll(() => {
        return client
          .query<{ pollCommentPackageSaveReady: PollReadyResult }>({
            query: POLL_SAVE_READY,
            variables: {
              projectId,
              applicationModifiedDate: result.applicationModifiedDate,
            },
            fetchPolicy: "no-cache",
          })
          .then(queryResult => {
            console.log("poll: ", queryResult.data.pollCommentPackageSaveReady.ready);
            if (queryResult.data.pollCommentPackageSaveReady.ready) {
              logger.debug("Publish polling finished");
              return result;
            } else if (queryResult.errors) {
              logger.error("Publish polling finished with errors:", queryResult.errors);
              return Promise.reject("Error while waiting publish to finish");
            } else {
              return undefined; // keep polling
            }
          });
      }, 45);
    })
    .then(result => {
      const pak = result.commentPackage;
      if (pak) {
        updateCache(projectId, nodeId, pak, client);
      }
      return result;
    })
    .catch(reason => {
      if (reason === PollingTimeout) {
        logger.warn("Comment package publishing polling timeout for project", projectId);
        return Promise.reject(
          "Publishing was not finished in time. The publishing may still have been successful, but it took too long to wait for it to finish." +
            " Please contact support, if the save did not go through after waiting 5 minutes."
        );
      } else {
        logger.error("Comment package publishing error for project", projectId, ":", reason);
        return Promise.reject(reason);
      }
    });
};
