import styled from "styled-components";
import { defaultGrey, disabledGrey, filterGreen, valmetGreyBorder } from "../../../../common/colors";

export const FullscreenWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background: ${disabledGrey};
  z-index: 1200;
  left: 0;
`;

export const Container = styled.div`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  border: 1px solid ${valmetGreyBorder};
  z-index: 1500;
  font-size: 24px;
  color:${defaultGrey}
  padding-top:20px;
  padding-bottom:20px;
  padding-left:25px;
  padding-right:25px;
  button:hover {
    background: ${filterGreen};
  }
  align-self: flex-start;
  margin-top: 150px;
  width: 675px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div``;

export const ContentSection = styled.div`
  margin-top: 20px;
`;

export const SectionTitle = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
`;
