import { ApolloClient } from "@apollo/client";
import {
  HeaderInfoData,
  HierarchyItem,
  HierarchyItemEnum,
  MainProjectChangeRequest,
  NewNodeRequest,
  NodeRequest,
  ProjectId,
  ProjectRequest,
  RemoveNodeRequest,
  SaveHierarchyChangesResponse,
} from "../../../../../common/types";
import { GET_HEADER_INFO, HierarchyFields } from "../../queries";
import { cloneDeep } from "lodash";
import gql from "graphql-tag";

export const SAVE_HIERARCHY_CHANGES = gql`
  mutation SaveHierarchyChanges(
    $projectId: ProjectId!
    $newNodes: [NewNodeRequest!]!
    $movedProjects: [AddProjectToHierarchyRequest!]!
    $movedNodes: [NodeRequest!]!
    $removedNodes: [RemoveNodeRequest!]!
    $nodeMainProjectChanges: [MainProjectChangeRequest!]!
  ) {
    saveHierarchyChanges(
      projectId: $projectId
      newNodes: $newNodes
      movedProjects: $movedProjects
      movedNodes: $movedNodes
      removedNodes: $removedNodes
      nodeMainProjectChanges: $nodeMainProjectChanges
    ) {
      applicationModifiedDateTime
      nodeIds
      updatedHierarchy {
        ...HierarchyFields
        childItems {
          ...HierarchyFields
          childItems {
            ...HierarchyFields
            childItems {
              ...HierarchyFields
              childItems {
                ...HierarchyFields
                childItems {
                  ...HierarchyFields
                  childItems {
                    ...HierarchyFields
                    childItems {
                      ...HierarchyFields
                      childItems {
                        ...HierarchyFields
                        childItems {
                          ...HierarchyFields
                          childItems {
                            ...HierarchyFields
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      errors
    }
  }
  ${HierarchyFields}
`;

export const saveProjectHierarchyChanges = async (
  client: ApolloClient<Record<string, unknown>>,
  setErrors: (errors: string[] | undefined) => void,
  newNodes: NewNodeRequest[],
  movedNodes: NodeRequest[],
  movedProjects: ProjectRequest[],
  removedNodes: RemoveNodeRequest[],
  mainProjectChanges: MainProjectChangeRequest[],
  itemId: ProjectId
): Promise<SaveHierarchyChangesResponse | null> => {
  return await client
    .mutate<{ saveHierarchyChanges: SaveHierarchyChangesResponse }>({
      mutation: SAVE_HIERARCHY_CHANGES,
      variables: {
        projectId: itemId,
        newNodes: newNodes,
        movedProjects: movedProjects,
        movedNodes: movedNodes,
        removedNodes: removedNodes.map(node => {
          return { nodeId: node.nodeId, nodeDescription: node.description };
        }),
        nodeMainProjectChanges: mainProjectChanges,
      },
    })
    .then(({ data }) => {
      if (data && data.saveHierarchyChanges.applicationModifiedDateTime && data.saveHierarchyChanges.updatedHierarchy)
        return data.saveHierarchyChanges;
      else {
        if (data && data.saveHierarchyChanges.errors) {
          setErrors(data.saveHierarchyChanges.errors);
        } else {
          setErrors(["Error saving hierarchy data."]);
        }
        return null;
      }
    })
    .catch(e => {
      setErrors([e.message]);
      return e;
    });
};

export const saveEditedProjectHierarchy = async (
  client: ApolloClient<Record<string, unknown>>,
  setErrors: (errors: string[] | undefined) => void,
  newNodes: NewNodeRequest[],
  movedNodes: NodeRequest[],
  movedProjects: ProjectRequest[],
  removedNodes: RemoveNodeRequest[],
  mainProjectChanges: MainProjectChangeRequest[],
  itemId: ProjectId,
  setProjectHierarchy: (hierarchy: HierarchyItem) => void
): Promise<{ successful: boolean; applicationModifiedDateTime?: string }> => {
  const data = await saveProjectHierarchyChanges(
    client,
    setErrors,
    newNodes,
    movedNodes,
    movedProjects,
    removedNodes,
    mainProjectChanges,
    itemId
  );

  if (data && data.applicationModifiedDateTime && data.updatedHierarchy) {
    const queryData = client.readQuery<HeaderInfoData>({
      query: GET_HEADER_INFO,
      variables: { id: itemId, itemType: HierarchyItemEnum.Project },
    });
    const updatedData = cloneDeep(queryData);
    if (updatedData && updatedData.headerInfo) {
      updatedData.headerInfo.hierarchy = data.updatedHierarchy;

      client.writeQuery({
        query: GET_HEADER_INFO,
        variables: { id: itemId, itemType: HierarchyItemEnum.Project },
        data: updatedData,
      });
      setProjectHierarchy(updatedData.headerInfo.hierarchy);
      return { successful: true, applicationModifiedDateTime: data.applicationModifiedDateTime };
    } else {
      setErrors(["Hierarchy cache update failed."]);
      return { successful: false, applicationModifiedDateTime: undefined };
    }
  } else {
    return { successful: false, applicationModifiedDateTime: undefined };
  }
};
