import gql from "graphql-tag";

export const GET_PROJECT_PHASES = gql`
  query GetProjectPhases {
    projectPhases {
      id
      description
    }
  }
`;

export const GET_PROJECT_TECHNICAL_TYPES = gql`
  query GetProjectTechnicalTypes {
    projectTechnicalTypes {
      id
      description
    }
  }
`;

export const GET_PROJECT_TYPES = gql`
  query GetProjectTypes {
    projectTypes {
      id
      description
    }
  }
`;

export const GET_PROJECT_STATUSES = gql`
  query GetProjectStatuses {
    projectStatuses {
      id
      description
    }
  }
`;

export const GET_FINANCING_METHODS = gql`
  query GetFinancingMethods {
    financingMethods
  }
`;

const OrganisationKeyHierarchyFields = gql`
  fragment OrganisationKeyHierarchyFields on OrganisationKeyHierarchy {
    id
    code
    description
  }
`;

export const GET_BUSINESS_GROUP_HIERARCHIES = gql`
  query GetBusinessGroupHierarchies {
    businessGroupHierarchies {
      ...OrganisationKeyHierarchyFields
      children {
        ...OrganisationKeyHierarchyFields
        children {
          ...OrganisationKeyHierarchyFields
          children {
            ...OrganisationKeyHierarchyFields
            children {
              ...OrganisationKeyHierarchyFields
              children {
                ...OrganisationKeyHierarchyFields
              }
            }
          }
        }
      }
    }
  }
  ${OrganisationKeyHierarchyFields}
`;

export const GET_ORGANISATION_KEY_VALUES = gql`
  query GetOrganisationKeyValues {
    organisationKeys {
      businessGroups {
        id
        description
      }
      businessTypes {
        id
        description
      }
      legalEntities {
        id
        description
      }
      externalLegalEntities {
        id
        description
      }
      deliveryOrgs {
        id
        description
      }
    }
  }
`;

export const GET_FILTER_PERSONS = gql`
  query GetFilterPersons {
    persons {
      userId
      firstName
      lastName
    }
  }
`;

export const GET_COUNTRIES = gql`
  query GetCountries_Filters {
    countries {
      id
      name
      code
    }
  }
`;
