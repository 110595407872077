import gql from "graphql-tag";
import { CreateNewProjectResult, EntityTypeId, PollReadyResult, ProjectRelationInput } from "../../../../common/types";
import { useLazyQuery, useMutation } from "@apollo/client/react/hooks";
import { useCallback, useState } from "react";

export const CREATE_NEW_PROJECT = gql`
  mutation CreateNewProject(
    $projectTechnicalTypeId: EntityTypeId!
    $relations: [ProjectRelationInput!]
    $relatingProjectId: ProjectId
  ) {
    createNewProject(
      projectTechnicalTypeId: $projectTechnicalTypeId
      relations: $relations
      relatingProjectId: $relatingProjectId
    ) {
      projectId
      applicationModifiedDateTime
      errors
    }
  }
`;

const POLL_FOR_PROJECT_CREATION_READY = gql`
  query PollForProjectCreationReady($projectId: ProjectId!, $applicationModifiedDateTime: ISOLocalDateTime!) {
    pollForProjectCreationReady(projectId: $projectId, applicationModifiedDateTime: $applicationModifiedDateTime) {
      ready
    }
  }
`;

export const pollForProjectCreationReady = (
  pollInterval = 2000,
  retries = 150
): {
  pollForReady: (projectId: number, applicationModifiedDateTime: string) => void;
  ready: boolean;
  loading: boolean;
  error: string | undefined;
} => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [leftRetries, setLeftRetries] = useState<number>(0);

  const [queryForProjectCreationReady, { data, startPolling, stopPolling }] = useLazyQuery<{
    pollForProjectCreationReady: PollReadyResult;
  }>(POLL_FOR_PROJECT_CREATION_READY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    onCompleted(data) {
      if ((!data || !data.pollForProjectCreationReady.ready) && leftRetries > 0) {
        if (leftRetries === retries) {
          startPolling(pollInterval);
        }
        setLeftRetries(leftRetries - 1);
      } else {
        localStorage.removeItem("projectCreationPolling");
        setLeftRetries(0);
        setLoading(false);
        stopPolling();
        if (!data || !data.pollForProjectCreationReady.ready) {
          setError("Creating project failed. Polling for result failed.");
        }
      }
    },
    onError(error) {
      if (leftRetries > 0) {
        if (leftRetries === retries) {
          startPolling(pollInterval);
        }
        setLeftRetries(leftRetries - 1);
      } else {
        localStorage.removeItem("projectCreationPolling");
        setLeftRetries(0);
        setLoading(false);
        stopPolling();
        setError(error.message);
      }
    },
  });

  const pollForReady = useCallback(
    (projectId: number, applicationModifiedDateTime: string) => {
      setLoading(true);
      localStorage.setItem("projectCreationPolling", JSON.stringify({ projectId, applicationModifiedDateTime }));
      setLeftRetries(retries);
      queryForProjectCreationReady({ variables: { projectId, applicationModifiedDateTime } });
    },
    [queryForProjectCreationReady]
  );

  return {
    pollForReady: pollForReady,
    ready: data ? data.pollForProjectCreationReady.ready : false,
    loading: loading,
    error: error,
  };
};

export const createNewProject = (): {
  create: (
    projectType: EntityTypeId,
    relations: ProjectRelationInput[] | undefined,
    relatingProjectId: number | undefined
  ) => void;
  newProjectId: number | undefined;
  applicationModifiedDateTime: string | undefined;
  loading: boolean;
  error: string | undefined;
} => {
  const [creatNewProjectMutation] = useMutation<{ createNewProject: CreateNewProjectResult }>(CREATE_NEW_PROJECT);
  const [newProjectId, setNewProjectId] = useState<number | undefined>(undefined);
  const [applicationModifiedDateTime, setApplicationModifiedDateTime] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const create = useCallback(
    (
      projectType: EntityTypeId,
      relations: ProjectRelationInput[] | undefined,
      relatingProjectId: number | undefined
    ) => {
      setLoading(true);
      setError(undefined);
      creatNewProjectMutation({
        variables: { projectTechnicalTypeId: projectType, relations, relatingProjectId },
      })
        .then(({ data }) => {
          if (!data) {
            setError("Creating project failed");
            setNewProjectId(undefined);
            setApplicationModifiedDateTime(undefined);
          } else {
            const { projectId, applicationModifiedDateTime, errors } = data.createNewProject;
            if (errors && errors.length > 0) {
              setError(`Creating project failed. Error response ${errors}`);
              setNewProjectId(undefined);
              setApplicationModifiedDateTime(undefined);
            } else if (!projectId || !applicationModifiedDateTime) {
              setError(`Creating project failed. Invalid response ${projectId} ${applicationModifiedDateTime}`);
              setNewProjectId(undefined);
              setApplicationModifiedDateTime(undefined);
            } else {
              setNewProjectId(projectId);
              setApplicationModifiedDateTime(applicationModifiedDateTime);
            }
          }
          setLoading(false);
        })
        .catch(e => {
          setError(`Creating project failed. Error ${e}`);
          setNewProjectId(undefined);
          setApplicationModifiedDateTime(undefined);
          setLoading(false);
        });
    },
    [creatNewProjectMutation]
  );

  return {
    create: create,
    newProjectId: newProjectId,
    applicationModifiedDateTime: applicationModifiedDateTime,
    loading,
    error,
  };
};
