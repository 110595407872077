export const defaultGrey = "#4C4D4F";
export const defaultGreyDark = "#3C3D3F";
export const valmetGreyLight = "#6F6F6F";
export const valmetGreyBorder = "#BDBDBD";
export const valmetGreyHeader = "#707070";
export const valmetGreyTable = "#F5F5F5";
export const valmetDarkGreyTable = "#d8d8d8";
export const valmetGreyId = "#9A9A9A";
export const valmetGreyREC = "#D8D8D8";
export const valmetBlueTable = "#ddf9f9";
export const filterGreen = "#C6DCA6";
export const settingGreen = "#1F9122";
export const disabledSettingGreen = "#C6DCA680";
export const projectsYellow = "#FAE498";
export const projectsYellowTransparent = "rgba(250, 228, 152, 0.3)";
export const projectsYellowTransparentOnWhite = "rgba(254, 247, 224)";
export const projectDataYellow = "#faf8f3";
export const projectDataYellowBorder = "#eae2cf";
export const projectDetailsYellow = "#f5f0e6";
export const notificationHeaderYellow = "#f0e7e0";
export const warningYellow = "#f7f0c0";
export const recOblHighlightGreen = "#efffef";
export const recSelectedHighlightBlue = "#effdff";
export const recPeriodTagBackgroundGrey = "#dfeeee";
export const recFreezingSelectedBlue = "#a0e0ff";
export const disabledGrey = "#00000080";
export const lightGreen = "#50B948";
export const backgroundGrey = "#F2F2F2";
export const commentBlue = "#6CD5E1";
export const commentBackgroundBlue = "#BAE7E7";
export const commentTextBackgroundBlue = "#FFFFFF80";
export const greyTransparent = "#00000080";
export const valmetGreyLightTable = "#f9f9f9";
export const filterGreenTransparent = "#C6DCA680";
export const filterGreenMoreTransparent = "#C6DCA620";
export const errorRed = "#efaf94";
export const cancelRed = "#d9534f";
export const editBlue = "#0060ba";
export const costEditingBlockedYellow = projectDetailsYellow;
export const costEditingBlockedYellowBorder = valmetGreyId;
export const hierarchyItemPassThroughColor = "#dcb6c4";
// Used in rec table and costs view
export const selectedRowHighlight = "#C6DCA640";
export const selectedRowHighlightOpaque = "#D6ECB6";
export const selectedRowColor = "#919a85";
export const rateCurrencyPairsHighlight = "#dcb6c4";
