import React, { useCallback, useEffect, useMemo } from "react";
import {
  AppState,
  FieldProperties,
  ProjectDates,
  ProjectDatesEditInformation,
  ProjectDatesInput,
  ProjectDatesSectionInput,
  ProjectDatesVisibility,
  ProjectId,
  UserOverridable,
} from "../../../../../common/types";
import styled from "styled-components";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { setProjectDates, setProjectError } from "../../../../../actions/projectActions";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client/react/hooks";
import { GET_PROJECT_DATES_EDIT_INFORMATION } from "./queries";
import EditableMonth from "../../../../EditableMonth";
import LoadingView from "../../../../LoadingView";
import { UnlockIcon } from "../../../../../common/components";
import { parsePeriod, Period } from "../../../../../common/period";
import { getEmptyProjectDatesSectionInput } from "../../../../../common/constants";
import { DateValue, InformationItem, TitleItem } from "./shared-components";
import Tooltip from "../../../../Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { commentBlue } from "../../../../../common/colors";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export interface EditDetailsProps {
  projectId: ProjectId;
  dates: ProjectDates;
  datesVisibility: ProjectDatesVisibility;
}

const mapStateToProps = (state: AppState) => {
  return {
    sectionInput: state.projectState.projectInput ? state.projectState.projectInput.projectDates : undefined,
    editInformation: state.projectState.projectDetailsEditInformation
      ? state.projectState.projectDetailsEditInformation.projectDates
      : undefined,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setProjectDateInput: (input: ProjectDatesSectionInput) => dispatch(setProjectDates(input)),
    setProjectError: (value: boolean) => {
      dispatch(setProjectError(value, "date"));
    },
  };
};

const checkInputErrors = (
  sectionInput: ProjectDatesInput | undefined,
  editInformation: ProjectDatesEditInformation | undefined
) => {
  if (sectionInput && editInformation) {
    const errors: (keyof ProjectDatesInput)[] = [];
    const checkField = (fieldName: keyof ProjectDatesInput, fieldProperties: FieldProperties) => {
      if (sectionInput[fieldName] === null && fieldProperties.nullable !== null && !fieldProperties.nullable) {
        errors.push(fieldName);
      }
    };
    const checkUserOverridableField = (
      fieldName: keyof ProjectDatesInput,
      userOverridable: UserOverridable<Period>,
      fieldProperties: FieldProperties
    ) => {
      if (
        (sectionInput[fieldName] || (editInformation && editInformation.recPlanEndPeriod.cached)) === null &&
        fieldProperties.nullable !== null &&
        !fieldProperties.nullable
      ) {
        errors.push(fieldName);
      }
    };
    checkField("OBLPeriod", editInformation.OBLPeriodProperties);
    checkField("asSoldPeriod", editInformation.asSoldPeriodProperties);
    checkField("budgetPeriod", editInformation.budgetPeriodProperties);
    checkField("recPlanStartPeriod", editInformation.recPlanStartPeriodProperties);
    checkField("recCompletionPeriod", editInformation.recCompletionPeriodProperties);
    checkUserOverridableField(
      "recPlanEndPeriod",
      editInformation.recPlanEndPeriod,
      editInformation.recPlanEndPeriodProperties
    );
    checkField("startUpPeriod", editInformation.startUpPeriodProperties);
    checkField("projectClosingPeriodInERP", editInformation.projectClosingPeriodInERPProperties);
    checkField("warrantyStartPeriod", editInformation.warrantyStartPeriodProperties);
    checkField("warrantyEndPeriod", editInformation.warrantyEndPeriodProperties);
    checkField("migrationPeriod", editInformation.migrationPeriodProperties);
    return errors;
  } else {
    return [];
  }
};

function EditDetails(
  props: EditDetailsProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {
  const {
    projectId,
    dates,
    datesVisibility,
    sectionInput,
    editInformation,
    setProjectDateInput: setSectionInput,
    setProjectError,
  } = props;

  const { data, error, loading } = useQuery(GET_PROJECT_DATES_EDIT_INFORMATION, {
    variables: { projectId: projectId },
    skip: !projectId || editInformation !== undefined,
    // NOTE: need to check for changed edit information, as project information could have been saved
    //       with new business group, which can affect the dates edit information
    fetchPolicy: "cache-and-network",
  });

  const editInfo: ProjectDatesEditInformation | undefined = editInformation
    ? editInformation
    : !loading && !error && data
    ? data.projectDetailsEditInformation.projectDates
    : undefined;

  const datesInput = sectionInput ? sectionInput.input : undefined;

  const onEditInformationDataChange = useCallback(() => {
    if (editInfo)
      setSectionInput({
        input: {
          OBLPeriod: dates.OBLPeriod && editInfo.OBLPeriodProperties.editable ? dates.OBLPeriod : null,
          asSoldPeriod: dates.asSoldPeriod && editInfo.asSoldPeriodProperties.editable ? dates.asSoldPeriod : null,
          budgetPeriod: dates.budgetPeriod && editInfo.budgetPeriodProperties.editable ? dates.budgetPeriod : null,
          recPlanStartPeriod:
            dates.recPlanStartPeriod && editInfo.recPlanStartPeriodProperties.editable
              ? dates.recPlanStartPeriod
              : null,
          recPlanEndPeriod:
            dates.recPlanEndPeriod && editInfo.recPlanEndPeriodProperties.editable ? dates.recPlanEndPeriod : null,
          recCompletionPeriod:
            dates.recCompletionPeriod && editInfo.recCompletionPeriodProperties.editable
              ? dates.recCompletionPeriod
              : null,
          startUpPeriod: dates.startUpPeriod && editInfo.startUpPeriodProperties.editable ? dates.startUpPeriod : null,
          projectClosingPeriodInERP:
            dates.projectClosingPeriodInERP && editInfo.projectClosingPeriodInERPProperties.editable
              ? dates.projectClosingPeriodInERP
              : null,
          warrantyStartPeriod:
            dates.warrantyStartPeriod && editInfo.warrantyStartPeriodProperties.editable
              ? dates.warrantyStartPeriod
              : null,
          warrantyEndPeriod:
            dates.warrantyEndPeriod && editInfo.warrantyEndPeriodProperties.editable ? dates.warrantyEndPeriod : null,
          contractEffectivePeriod:
            dates.contractEffectivePeriod && editInfo.contractEffectivePeriodProperties.editable
              ? dates.contractEffectivePeriod
              : null,
          contractExpiryPeriod:
            dates.contractExpiryPeriod && editInfo.contractExpiryPeriodProperties.editable
              ? dates.contractExpiryPeriod
              : null,
          migrationPeriod:
            dates.migrationPeriod && editInfo.migrationPeriodProperties.editable ? dates.migrationPeriod : null,
          financeClosingPeriod:
            dates.financeClosingPeriod && editInfo.financeClosingPeriodProperties ? dates.financeClosingPeriod : null,
        },
        pristine: true,
      });
    else setSectionInput(getEmptyProjectDatesSectionInput());
  }, [editInfo, setSectionInput, dates]);

  useEffect(onEditInformationDataChange, [dates, editInfo]);

  const inputErrors = useMemo(() => checkInputErrors(datesInput, editInfo), [datesInput, editInfo]);
  useEffect(() => {
    setProjectError(inputErrors.length > 0);
  }, [inputErrors]);

  const fieldNameToEditInforField: { [key: string]: Exclude<keyof ProjectDatesEditInformation, "recPlanEndPeriod"> } = {
    OBLPeriod: "OBLPeriodProperties",
    asSoldPeriod: "asSoldPeriodProperties",
    budgetPeriod: "budgetPeriodProperties",
    recPlanStartPeriod: "recPlanStartPeriodProperties",
    recPlanEndPeriod: "recPlanEndPeriodProperties",
    recCompletionPeriod: "recCompletionPeriodProperties",
    projectClosingPeriodInERP: "projectClosingPeriodInERPProperties",
    startUpPeriod: "startUpPeriodProperties",
    warrantyEndPeriod: "warrantyEndPeriodProperties",
    warrantyStartPeriod: "warrantyStartPeriodProperties",
    contractEffectivePeriod: "contractEffectivePeriodProperties",
    contractExpiryPeriod: "contractExpiryPeriodProperties",
    migrationPeriod: "migrationPeriodProperties",
    financeClosingPeriod: "financeClosingPeriodProperties",
  };

  const dateField = (fieldName: keyof ProjectDatesInput & keyof ProjectDatesVisibility, displayName: string) => {
    if (datesInput && editInfo) {
      const valueOrNull = datesInput && datesInput[fieldName] ? datesInput[fieldName] : "";
      const value = valueOrNull !== null ? valueOrNull : "";
      const propsField = fieldNameToEditInforField[fieldName];
      const fieldProperties: FieldProperties = editInfo[propsField];
      return (
        <InformationItem>
          {datesVisibility[fieldName] && (
            <>
              <TitleItem>{displayName}:</TitleItem>
              {fieldProperties.editable ? (
                <EditableMonth
                  value={value}
                  onValueChanged={value =>
                    setSectionInput({ input: { ...datesInput, [fieldName]: value }, pristine: false })
                  }
                  error={inputErrors.includes(fieldName)}
                />
              ) : (
                <DateValue value={dates[fieldName]} />
              )}
            </>
          )}
        </InformationItem>
      );
    } else {
      return undefined;
    }
  };

  const getUserOverridableDateField = (
    fieldName: keyof ProjectDatesInput & keyof ProjectDatesVisibility,
    displayName: string,
    userOverridable: UserOverridable<Period>,
    infoText?: React.ReactElement,
    valueModify?: (period: string) => string
  ) => {
    if (datesInput && editInfo) {
      const value = datesInput[fieldName];
      const propsField = fieldNameToEditInforField[fieldName];
      const fieldProperties: FieldProperties = editInfo && editInfo[propsField];
      return (
        <InformationItem>
          {datesVisibility[fieldName] && (
            <>
              <TitleItem>
                {displayName}:
                {infoText && (
                  <Tooltip component={infoText}>
                    <FontAwesomeIcon icon={faInfoCircle} size="1x" color={commentBlue} />
                  </Tooltip>
                )}
                {value && userOverridable.cached && (
                  <UnlockIcon
                    onClick={() => setSectionInput({ input: { ...datesInput, [fieldName]: null }, pristine: false })}
                  />
                )}
              </TitleItem>
              {fieldProperties.editable ? (
                <EditableMonth
                  value={value !== null ? value : ((userOverridable.cached as unknown) as string) || ""}
                  onValueChanged={value => {
                    const v = valueModify && value !== null ? valueModify(value) : value;
                    setSectionInput({ input: { ...datesInput, [fieldName]: v }, pristine: false });
                  }}
                  error={inputErrors.includes(fieldName)}
                />
              ) : (
                <DateValue value={dates[fieldName]} />
              )}
            </>
          )}
        </InformationItem>
      );
    } else {
      return undefined;
    }
  };

  return loading || !sectionInput ? (
    <LoadingContainer>
      <LoadingView />
    </LoadingContainer>
  ) : editInfo !== undefined && !error ? (
    <DateSection>
      {dateField("recPlanStartPeriod", "Project start period")}
      {dateField("OBLPeriod", "Order backlog period")}
      {dateField("contractEffectivePeriod", "Contract effective period")}

      {dateField("asSoldPeriod", "As sold period")}
      {dateField("budgetPeriod", "Budget period")}
      {dateField("startUpPeriod", "Start-up period")}

      {dateField("warrantyStartPeriod", "Warranty start period")}
      {dateField("recCompletionPeriod", "Rec completion period")}
      {dateField("contractExpiryPeriod", "Contract expiry period")}

      {dateField("warrantyEndPeriod", "Warranty end period")}
      {dateField("projectClosingPeriodInERP", "Project closing period")}
      {getUserOverridableDateField(
        "recPlanEndPeriod",
        "Project end period",
        editInfo.recPlanEndPeriod,
        <div>
          The project end period will be determined by which of the following is the greatest:
          <ul>
            <li>Highest project end period in the project hierarchy.</li>
            <li>User edited value for project end period.</li>
            <li>Original value from the source system for projects with ERP links.</li>
          </ul>
          Finally, the period will be always forced to be the December of the year.
        </div>,
        period => {
          // Force the period into December of the selected year
          const p = parsePeriod(period);
          if (p) {
            return p.year + "-12";
          } else return period;
        }
      )}
      {dateField("migrationPeriod", "Migration period")}
      {dateField("financeClosingPeriod", "AS BL transfer to finance")}
    </DateSection>
  ) : (
    <LoadingContainer>{error && "Error loading editing data."}</LoadingContainer>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDetails);

const LoadingContainer = styled.div``;

const DateSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;
