import { gql } from "@apollo/client";
import { FieldPropertiesFields } from "../queries";

export const GET_REPORTING_RELATIONS_EDIT_INFORMATION = gql`
  query GetReportingRelationsEditInformation($projectId: ProjectId!) {
    projectDetailsEditInformation(projectId: $projectId) {
      reportingRelations {
        externalOrganisation {
          businessGroupId
          businessGroupProperties {
            ...FieldPropertiesFields
          }
          businessTypeId
          businessTypeProperties {
            ...FieldPropertiesFields
          }
          legalEntityId
          legalEntityProperties {
            ...FieldPropertiesFields
          }
          reportingId
          reportingProperties {
            ...FieldPropertiesFields
          }
          customerId
          customerProperties {
            ...FieldPropertiesFields
          }
          externalReportingIndustryId
          externalReportingIndustryProperties {
            ...FieldPropertiesFields
          }
          orderValueIncludedInRelated
          orderValueIncludedInRelatedProperties {
            ...FieldPropertiesFields
          }
        }
        serMarginTraceabilityProperties {
          ...FieldPropertiesFields
        }
      }
    }
  }
  ${FieldPropertiesFields}
`;

export const SAVE_REPORTING_RELATIONS = gql`
  mutation SaveProjectReportingRelations($projectId: ProjectId!, $reportingRelations: ProjectReportingRelationsInput!) {
    saveProjectReportingRelations(projectId: $projectId, reportingRelations: $reportingRelations) {
      result {
        relatingProjectId
        relatingProjectDesc
        externalOrganisation {
          businessGroup
          businessType
          legalEntity
          reporting
          customerId
          customerName
          externalReportingIndustry
          orderValueIncludedInRelated
        }
        serMarginTraceability
        relatedLegalCompany
      }
      applicationModifiedDateTime
      errors
    }
  }
`;
