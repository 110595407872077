import gql from "graphql-tag";
import { FieldPropertiesFields } from "../queries";

export const GET_PROJECT_CURRENCY_EDIT_INFORMATION = gql`
  query GetProjectCurrencyEditInformation($projectId: ProjectId!) {
    projectDetailsEditInformation(projectId: $projectId) {
      projectCurrency {
        contractCurrencyProperties {
          ...FieldPropertiesFields
        }
        legalEntityCurrencyProperties {
          ...FieldPropertiesFields
        }
        projectCurrencyProperties {
          ...FieldPropertiesFields
        }
        revenueRecognitionCurrencyProperties {
          ...FieldPropertiesFields
        }
        externalReportingCurrencyProperties {
          ...FieldPropertiesFields
        }
        internalReportingCurrencyProperties {
          ...FieldPropertiesFields
        }
      }
    }
  }
  ${FieldPropertiesFields}
`;

export const GET_BASIC_DATA_CURRENCY = gql`
  query GetBasicDataCurrency {
    basicDataCurrency
  }
`;

export const SAVE_PROJECT_CURRENCY = gql`
  mutation SaveProjectCurrency($projectId: ProjectId!, $currency: ProjectCurrencyInput!) {
    saveProjectCurrency(projectId: $projectId, currency: $currency) {
      result {
        contractCurrency
        legalEntityCurrency
        projectCurrency
        revenueRecognitionCurrency
        externalReportingCurrency
        internalReportingCurrency
      }
      errors
      applicationModifiedDateTime
    }
  }
`;
