import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { ApolloClient, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";

import { ESTIMATION_SCHEDULE, POLL_ESTIMATION_SCHEDULE, SAVE_ESTIMATION_SCHEDULE } from "./queries";
import { EstimateSchedule, EstimateScheduleInput, EstimateScheduleYear, SaveResult } from "./types";
import LoadingView from "../../LoadingView";
import ErrorBox from "../../ErrorBox";
import {
  currentPeriod,
  parsePeriodOrFail,
  Period,
  periodAsInt,
  periodAsString,
  periodEquals,
  periodFromInt,
  periodLessThan,
  periodToString,
} from "../../../common/period";
import {
  cancelRed,
  defaultGrey,
  filterGreen,
  projectDetailsYellow,
  valmetGreyId,
  valmetGreyLightTable,
  valmetGreyREC,
  valmetGreyTable,
} from "../../../common/colors";
import { ActionButton } from "../../../common/components";
import { AppState, ISOLocalDateTime } from "../../../common/types";
import { connect } from "react-redux";
import { useApolloClient, useMutation } from "@apollo/client/react/hooks";
import FullscreenSpinner from "../../FullscreenSpinner";

const isOutside = (parent: HTMLElement, elem: HTMLElement): boolean => {
  let p: HTMLElement | null = parent;
  while (p != null) {
    if (p === elem) return false;
    p = p.parentElement;
  }
  return true;
};

const onClickOutside = (element: HTMLElement | null, onClick: () => void) => {
  useEffect(() => {
    if (!element) return;
    const handler = (ev: MouseEvent) => {
      if (ev.target === element) return;
      if (ev.target instanceof HTMLElement) {
        if (isOutside(ev.target, element)) {
          onClick();
        }
      }
    };
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, [element]);
};

const DateEdit = (props: { period: Period; initialDate: Date | null; onChange: (date: Date) => void }) => {
  const { period, initialDate, onChange } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [edit, setEdit] = useState(false);
  onClickOutside(ref.current ? ref.current : null, () => setEdit(false));

  const selectDate = (date: Date) => {
    setEdit(false);
    onChange(date);
  };

  const periodNow = currentPeriod();
  const isOld = periodLessThan(period, periodNow);
  const isNow = periodEquals(period, periodNow);

  return (
    <DateEditContainer ref={ref} isEditable={!isOld}>
      {isOld ? (
        <DateDisplay textStyle="disabled">{initialDate && initialDate.toLocaleDateString()}</DateDisplay>
      ) : (
        <DateDisplay onClick={() => setEdit(true)} edit={edit} textStyle={isNow ? "bold" : "normal"}>
          {initialDate && initialDate.toLocaleDateString()}
        </DateDisplay>
      )}
      {edit && <Calendar period={period} selectedDate={initialDate} onClick={selectDate} />}
    </DateEditContainer>
  );
};

const DateEditContainer = styled.div<{ isEditable: boolean }>`
  box-sizing: border-box;
  position: relative;
  ${({ isEditable }) => isEditable && "border: solid 2px #e0eeff;"}
`;

type CalendarProps = {
  period: Period;
  selectedDate: Date | null;
  onClick: (date: Date) => void;
};

enum Weekday {
  SUNDAY = 0,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

type CalendarDay = {
  day: number; // 1 to 31
  weekday: Weekday;
};

const Calendar = ({ period, selectedDate, onClick }: CalendarProps) => {
  const days = new Date(Date.UTC(period.year, period.month - 1, 0)).getDate();
  const monthDays: (CalendarDay | null)[][] = [];
  const date = new Date(Date.UTC(period.year, period.month - 1));
  const start = date.getDay(); // Sunday = 0
  let day = 1;
  let i = 0;
  let iterations = 0;
  while (day < days && iterations < 1000) {
    const week: (CalendarDay | null)[] = [];
    for (let wd = Weekday.SUNDAY; wd <= Weekday.SATURDAY; wd++) {
      if (i < start || day >= days) {
        week.push(null);
      } else {
        week.push({ day, weekday: wd });
        day++;
      }
      i++;
    }
    monthDays.push(week);
    iterations++;
  }
  return (
    <CalendarContainer>
      <MonthYearHeader>
        {period.month} / {period.year}
      </MonthYearHeader>
      <WeekContainer>
        {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((s, i) => (
          <DayContainer key={i} wd={i}>
            {s}
          </DayContainer>
        ))}
      </WeekContainer>
      <Separator />
      {monthDays.map((week, n) => (
        <WeekContainer key={n}>
          {week.map((day, i) =>
            day ? (
              <DayContainer
                key={i}
                wd={day.weekday}
                onClick={() => onClick(new Date(Date.UTC(period.year, period.month - 1, day.day)))}
                selected={!!selectedDate && day.day === selectedDate.getDate()}
              >
                {day.day}
              </DayContainer>
            ) : (
              <DayContainer key={i} wd={Weekday.MONDAY} />
            )
          )}
        </WeekContainer>
      ))}
    </CalendarContainer>
  );
};

const Separator = styled.hr`
  border: 1px solid ${valmetGreyTable};
  padding: 0;
  margin: 4px 2px;
`;

const MonthYearHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: ${projectDetailsYellow};
  padding: 10px;
  border-radius: 5px;
`;

const WeekContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DayContainer = styled.div<{ wd: Weekday; onClick?: unknown; selected?: boolean }>`
  padding: 5px;
  width: 20px;
  font-size: 14px;
  color: ${({ wd }) => {
    switch (wd) {
      case Weekday.SUNDAY:
      case Weekday.SATURDAY:
        return valmetGreyId;
      default:
        return "black";
    }
  }};
  ${({ onClick }) => (onClick ? "cursor: pointer;" : "")}
  background: ${({ selected }) => (selected ? filterGreen : "white")};
  border-radius: 6px;
`;

const CalendarContainer = styled.div`
  position: absolute;
  z-index: 1000;
  background: white;
  /*width: 200px;*/
  border: 1px solid #aaa;
  border-radius: 5px;
  right: -213px;
  top: -2px;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 4px 12px;
`;

const DateDisplay = styled.div<{
  onClick?: unknown;
  edit?: boolean;
  textStyle?: "normal" | "disabled" | "bold";
}>`
  box-sizing: border-box;
  height: 42px;
  padding: 10px;
  background: ${({ edit }) => (edit ? valmetGreyLightTable : "white")};
  ${({ onClick }) => (onClick ? "cursor: pointer;" : "")}
  color: ${({ textStyle }) => {
    switch (textStyle) {
      case "normal":
        return "black";
      case "disabled":
        return valmetGreyREC;
      case "bold":
        return "black";
    }
  }};
  font-weight: ${({ textStyle }) => {
    switch (textStyle) {
      case "normal":
        return "normal";
      case "disabled":
        return "normal";
      case "bold":
        return "bold";
    }
  }};
`;

const PeriodDisplay = styled.div`
  padding: 10px;
`;

const mapStateToProps = (state: AppState) => {
  return {
    user: state.authState.user,
  };
};

function doPolling(
  year: number,
  modifiedDate: ISOLocalDateTime,
  client: ApolloClient<Record<string, unknown>>
): Promise<void> {
  const query = () =>
    client.query<{ pollEstimationSchedule: { ready: boolean } }>({
      query: POLL_ESTIMATION_SCHEDULE,
      variables: {
        year,
        modifiedDate,
      },
    });

  return new Promise<void>((resolve, reject) => {
    const poll = (): Promise<void> => {
      return query().then(result => {
        if (result.data.pollEstimationSchedule.ready) {
          return resolve();
        } else {
          setTimeout(() => poll().then(resolve).catch(reject), 1000);
        }
      });
    };

    return poll();
  });
}

function EstimateApprovalSchedule(props: ReturnType<typeof mapStateToProps>) {
  const { user } = props;
  const { data, loading, error, refetch } = useQuery<{ estimationSchedule: EstimateSchedule }>(ESTIMATION_SCHEDULE);
  const [mutation, { loading: saving }] = useMutation<{ saveEstimationSchedule: SaveResult }>(SAVE_ESTIMATION_SCHEDULE);
  const [polling, setPolling] = useState(false);
  const [savingError, setSavingError] = useState<string | null>(null);
  const client = useApolloClient() as ApolloClient<Record<string, unknown>>;

  const [editYear, setEditYear] = useState<number | null>(null);
  const [input, setInput] = useState<{ [key: string]: Date }>({});
  const clearInput = () => setInput({});

  const setDate = (p: Period, d: Date) => {
    const key = periodAsString(p);
    const newInput = { ...input, [key]: d };
    setInput(newInput);
  };

  const schedule: EstimateSchedule = useMemo(() => {
    if (!data) return { years: [] };
    return {
      years: data.estimationSchedule.years.map(year =>
        year.year === editYear
          ? {
              year: year.year,
              periods: year.periods.map(period => {
                const p = parsePeriodOrFail(period.period);
                const stored = input[periodAsString(p)];
                if (stored) {
                  return { period: period.period, schedule: stored.toISOString() };
                } else {
                  return period;
                }
              }),
            }
          : year
      ),
    };
  }, [data, input]);

  const endEditing = () => {
    clearInput();
    setEditYear(null);
  };

  const startPolling = useCallback(
    (year: number, modifiedDate: ISOLocalDateTime) => {
      setPolling(true);
      return doPolling(year, modifiedDate, client)
        .then(() => {
          setPolling(false);
          endEditing();
          refetch();
        })
        .catch(reason => {
          setPolling(false);
          setSavingError(reason.toString());
        });
    },
    [client, setPolling, refetch, setSavingError]
  );

  const canEdit = user && user.isAdmin;

  const save = useCallback(() => {
    if (!canEdit) return;
    if (editYear === null) return;
    setSavingError(null);

    const saveInput: EstimateScheduleInput = {
      year: editYear,
      periods: Object.entries(input).map(([period, schedule]) => {
        return {
          period: parsePeriodOrFail(period),
          schedule: schedule.toISOString(),
        };
      }),
    };
    return mutation({
      variables: {
        input: saveInput,
      },
    }).then(result => {
      console.log("Result", result);
      if (result.data) {
        const { year, modifiedDate } = result.data.saveEstimationSchedule;
        startPolling(year, modifiedDate);
      } else {
        setSavingError("Polling failed!");
        endEditing();
      }
    });
  }, [mutation, input, client, setSavingError]);

  const periodNow = currentPeriod();
  return (
    <Container>
      <Header>
        <h1>Estimate Approval Schedule for Internal Projects</h1>
      </Header>
      <ContentContainer>
        {savingError && <ErrorBox caption={"Error saving schedule"} errorText={savingError} />}
        {loading ? (
          <LoadingView />
        ) : error ? (
          <ErrorBox caption="Error" apolloError={error} />
        ) : data ? (
          schedule.years.map(year => {
            return (
              <YearContainer key={year.year}>
                <YearHeader>
                  <div>{year.year}</div>
                  {user && canEdit && (
                    <ButtonContainer>
                      {editYear !== year.year ? (
                        <ActionButton
                          onClick={() => {
                            clearInput();
                            setEditYear(year.year);
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </ActionButton>
                      ) : (
                        <>
                          <ActionButton onClick={save} disabled={saving}>
                            <FontAwesomeIcon icon={faSave} /> Save
                          </ActionButton>
                          <ActionButton color={cancelRed} onClick={endEditing} disabled={saving}>
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                          </ActionButton>
                        </>
                      )}
                    </ButtonContainer>
                  )}
                </YearHeader>
                <Table>
                  <thead>
                    <tr>
                      <th>Period</th>
                      <th>Schedule</th>
                    </tr>
                  </thead>
                  <tbody>
                    {year.periods.map(period => {
                      const p = parsePeriodOrFail(period.period);
                      const date = period.schedule !== null ? new Date(period.schedule) : null;
                      const isOld = periodLessThan(p, periodNow);
                      const isNow = periodEquals(p, periodNow);
                      return (
                        <tr key={period.period}>
                          <td>
                            <PeriodDisplay>{periodToString(p)}</PeriodDisplay>
                          </td>
                          <td>
                            {editYear !== year.year ? (
                              <DateDisplay textStyle={isOld ? "disabled" : isNow ? "bold" : "normal"}>
                                {date ? date.toLocaleDateString() : "-"}
                              </DateDisplay>
                            ) : (
                              <DateEdit period={p} initialDate={date} onChange={date => setDate(p, date)} />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </YearContainer>
            );
          })
        ) : (
          "-"
        )}
        {(saving || polling) && <FullscreenSpinner text={"Saving..."} />}
      </ContentContainer>
    </Container>
  );
}

export default connect(mapStateToProps)(EstimateApprovalSchedule);

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
`;

const ContentContainer = styled.div<{ extraMargin?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const YearContainer = styled.div``;

const YearHeader = styled.h2`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-self: flex-end;
  button {
    font-size: 16px;
    font-weight: bold;
  }
`;

const Table = styled.table`
  border-collapse: collapse;

  td,
  th {
    border: 1px solid #aaa;
    /*padding: 10px;*/
    min-width: 200px;
    text-align: center;
  }

  th {
    padding: 10px;
    color: ${defaultGrey};
    background: ${projectDetailsYellow};
    text-align: center;
  }
`;
