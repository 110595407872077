import React, { useMemo } from "react";
import {
  AppState,
  AutReportingCountriesResult,
  CountriesResult,
  DeliveryLocation,
  HierarchyItem,
  OrganisationKeysResult,
  ProjectDeliveryLocationsResult,
  ProjectId,
  ProjectInformation,
  ProjectInformationEditInformation,
  ProjectInformationInput,
  ProjectInformationSectionInput,
  ProjectInformationVisibility,
  ProjectPhasesResult,
  ProjectStatusesResult,
  ProjectTechnicalTypesResult,
  ProjectTypesResult,
} from "../../../../../../common/types";
import { parseNumberOrNull } from "../../../../../../common/utils";
import EditableSearchableDropdown from "../../EditableComponents/EditableSearchableDropdown";
import {
  DataItem,
  DataSection,
  InformationItem,
  ProjectDataSection,
  ProjectDataSectionTwo,
  TitleItem,
} from "../EditDetails";
import {
  combineToString,
  getDataItem,
  getProjecTypeDataItem,
  visibilityCheckWithOverride,
  editClone,
} from "../../utils";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { setProjectInformation } from "../../../../../../actions/projectActions";
import { connect } from "react-redux";
import { UnlockIcon } from "../../../../../../common/components";
import EditableSearchableHierarchyDropdown from "../../EditableComponents/EditableSearchableHierarchyDropdown/EditableSearchableHierarchyDropdown";
import { HierarchyDropdownItem } from "../../EditableComponents/EditableSearchableHierarchyDropdown/HierarchyDropdown";
import Tooltip from "../../../../../Tooltip";
import { DetailsField } from "../checkInputErrors";

type InformationDataSectionProps = {
  editInfo: ProjectInformationEditInformation;
  organisationKeysData: OrganisationKeysResult;
  projectInformation: ProjectInformation;
  inputErrors: DetailsField[];
  projectId: ProjectId;
  projectTechnicalTypesData: ProjectTechnicalTypesResult;
  projectTypesData: ProjectTypesResult;
  projectPhasesData: ProjectPhasesResult;
  projectStatusesData: ProjectStatusesResult;
  countriesData: CountriesResult;
  autReportingCountriesData: AutReportingCountriesResult;
  hierarchy: HierarchyItem | undefined;
  deliveryLocationsHierarchy: ProjectDeliveryLocationsResult;
  projectInformationVisibility: ProjectInformationVisibility;
};

const mapStateToProps = (state: AppState) => {
  return {
    sectionInput: state.projectState.projectInput ? state.projectState.projectInput.projectInformation : undefined,
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setSectionInput: (input: ProjectInformationSectionInput) => dispatch(setProjectInformation(input)),
  };
};

const InformationDataSection: React.FC<
  InformationDataSectionProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = (
  props: InformationDataSectionProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) => {
  const {
    sectionInput,
    setSectionInput,
    editInfo,
    organisationKeysData,
    projectInformation,
    inputErrors,
    projectId,
    projectTechnicalTypesData,
    projectTypesData,
    projectPhasesData,
    projectStatusesData,
    countriesData,
    autReportingCountriesData,
    hierarchy,
    deliveryLocationsHierarchy,
    projectInformationVisibility,
  } = props;

  function toHierarchyDropdownItem(location: DeliveryLocation, depth: number): HierarchyDropdownItem {
    return {
      id: location.id.toString(),
      desc: location.desc,
      description: location.description,
      depth: depth,
      childItems:
        location.childItems && location.childItems.map(subLocation => toHierarchyDropdownItem(subLocation, depth + 1)),
    };
  }

  const deliveryLocationDropdownItems: HierarchyDropdownItem[] = useMemo(
    () => deliveryLocationsHierarchy.deliveryLocations.map(location => toHierarchyDropdownItem(location, 0)),
    [deliveryLocationsHierarchy]
  );

  const setSectionInputWithoutInherited = (newInput: ProjectInformationInput) =>
    setSectionInput({
      input: newInput,
      pristine: false,
      inherited: sectionInput ? sectionInput.inherited : undefined,
    });

  return sectionInput !== undefined ? (
    <DataSection>
      <ProjectDataSection>
        <InformationItem>
          <TitleItem>Project ID:</TitleItem>
          <DataItem>{projectId}</DataItem>
        </InformationItem>
        <InformationItem>
          {projectInformationVisibility.projectTechnicalType && (
            <>
              <TitleItem>Project technical type:</TitleItem>
              {editInfo.projectTechnicalTypeProperties.editable ? (
                <EditableSearchableDropdown
                  value={
                    sectionInput.input.projectTechnicalTypeId !== null ? sectionInput.input.projectTechnicalTypeId : ""
                  }
                  onValueChanged={value =>
                    setSectionInputWithoutInherited({
                      ...sectionInput.input,
                      projectTechnicalTypeId: parseNumberOrNull(value),
                    })
                  }
                  options={projectTechnicalTypesData.projectTechnicalTypes.map(projectType => {
                    return { id: projectType.id, description: projectType.description };
                  })}
                  searchable={false}
                  disabled={!editInfo.projectTechnicalTypeProperties.editable}
                  error={inputErrors.includes(DetailsField.projectTechnicalTypeId)}
                />
              ) : (
                getProjecTypeDataItem(
                  projectTechnicalTypesData.projectTechnicalTypes,
                  sectionInput.input.projectTechnicalTypeId,
                  projectInformation.projectTechnicalType
                )
              )}
            </>
          )}
        </InformationItem>
        <InformationItem>
          {projectInformationVisibility.projectType && (
            <>
              <TitleItem>
                Project type:
                {sectionInput.input.projectTypeId && editInfo.projectTypeId.cached && (
                  <UnlockIcon
                    onClick={() =>
                      setSectionInputWithoutInherited(editClone(sectionInput.input, inp => (inp.projectTypeId = null)))
                    }
                  />
                )}
              </TitleItem>
              {editInfo.projectTypeProperties.editable ? (
                <EditableSearchableDropdown
                  value={
                    sectionInput.input.projectTypeId !== null
                      ? sectionInput.input.projectTypeId
                      : editInfo.projectTypeId.cached || ""
                  }
                  onValueChanged={value =>
                    setSectionInputWithoutInherited({ ...sectionInput.input, projectTypeId: parseNumberOrNull(value) })
                  }
                  options={projectTypesData.projectTypes.map(projectType => {
                    return { id: projectType.id, description: projectType.description };
                  })}
                  searchable={false}
                  disabled={!editInfo.projectTypeProperties.editable}
                  error={inputErrors.includes(DetailsField.projectTypeId)}
                />
              ) : (
                getDataItem(projectInformation.projectType)
              )}
            </>
          )}
        </InformationItem>
        <InformationItem>
          {projectInformationVisibility.projectPhase && (
            <>
              <TitleItem>Project phase:</TitleItem>
              <Tooltip message={editInfo.projectPhaseProperties.message} offset={{ x: 0, y: -0 }}>
                {editInfo.projectPhaseProperties.editable ? (
                  <EditableSearchableDropdown
                    value={sectionInput.input.projectPhaseId !== null ? sectionInput.input.projectPhaseId : ""}
                    onValueChanged={value =>
                      setSectionInputWithoutInherited({
                        ...sectionInput.input,
                        projectPhaseId: parseNumberOrNull(value),
                      })
                    }
                    options={projectPhasesData.projectPhases
                      .filter(projectPhase => {
                        enum ProjectPhaseId {
                          Warranty = 4,
                          WarrantyEnded = 5,
                        }
                        return (
                          projectPhase.id !== ProjectPhaseId.Warranty &&
                          projectPhase.id !== ProjectPhaseId.WarrantyEnded
                        );
                      })
                      .map(projectPhase => {
                        return { id: projectPhase.id, description: projectPhase.description };
                      })}
                    searchable={false}
                    disabled={!editInfo.projectPhaseProperties.editable}
                    error={inputErrors.includes(DetailsField.projectPhaseId)}
                  />
                ) : (
                  getDataItem(projectInformation.projectPhase)
                )}
              </Tooltip>
            </>
          )}
        </InformationItem>
        <InformationItem>
          {projectInformationVisibility.projectStatus && (
            <>
              <TitleItem>Project status:</TitleItem>
              <Tooltip message={editInfo.projectStatusProperties.message} offset={{ x: 0, y: -0 }}>
                {editInfo.projectStatusProperties.editable ? (
                  <EditableSearchableDropdown
                    value={sectionInput.input.projectStatusId !== null ? sectionInput.input.projectStatusId : ""}
                    onValueChanged={value =>
                      setSectionInputWithoutInherited({
                        ...sectionInput.input,
                        projectStatusId: parseNumberOrNull(value),
                      })
                    }
                    options={projectStatusesData.projectStatuses.map(projectStatus => {
                      return { id: projectStatus.id, description: projectStatus.description };
                    })}
                    searchable={false}
                    disabled={!editInfo.projectStatusProperties.editable}
                    error={inputErrors.includes(DetailsField.projectStatusId)}
                  />
                ) : (
                  getDataItem(projectInformation.projectStatus)
                )}
              </Tooltip>
            </>
          )}
        </InformationItem>
        <InformationItem>
          {projectInformationVisibility.projectCardStatus && (
            <>
              <TitleItem>Project card status:</TitleItem>
              {getDataItem(projectInformation.projectCardStatus)}
            </>
          )}
        </InformationItem>
      </ProjectDataSection>
      <ProjectDataSection>
        <InformationItem>
          {projectInformationVisibility.projectOffice && (
            <>
              <TitleItem>Project office:</TitleItem>
              {getDataItem(projectInformation.projectOffice)}
            </>
          )}
        </InformationItem>
        <InformationItem>
          {projectInformationVisibility.EDC && (
            <>
              <TitleItem>EDC:</TitleItem>
              {editInfo.EDCProperties.editable ? (
                <EditableSearchableDropdown
                  value={sectionInput.input.EDCId !== null ? sectionInput.input.EDCId : ""}
                  onValueChanged={value =>
                    setSectionInputWithoutInherited({ ...sectionInput.input, EDCId: parseNumberOrNull(value) })
                  }
                  options={countriesData.countries.map(country => {
                    return { id: country.id, description: country.name };
                  })}
                  disabled={!editInfo.EDCProperties.editable}
                  error={inputErrors.includes(DetailsField.EDCId)}
                  searchable={true}
                  maxResults={20}
                />
              ) : (
                getDataItem(projectInformation.EDC)
              )}
            </>
          )}
        </InformationItem>
        <InformationItem>
          {visibilityCheckWithOverride(
            projectInformationVisibility.autReportingCountry,
            sectionInput.input,
            "autReportingCountryId",
            organisationKeysData.organisationKeys.businessGroups,
            projectInformation.projectVirtualType
          ) && (
            <>
              <TitleItem>AUT reporting country:</TitleItem>
              {editInfo.autReportingCountryProperties.editable ? (
                <EditableSearchableDropdown
                  value={
                    sectionInput.input.autReportingCountryId !== null ? sectionInput.input.autReportingCountryId : ""
                  }
                  onValueChanged={value =>
                    setSectionInputWithoutInherited({
                      ...sectionInput.input,
                      autReportingCountryId: parseNumberOrNull(value),
                    })
                  }
                  options={autReportingCountriesData.autReportingCountries.map(country => {
                    return { id: country.id, description: country.name };
                  })}
                  disabled={!editInfo.autReportingCountryProperties.editable}
                  error={inputErrors.includes(DetailsField.autReportingCountryId)}
                  searchable={true}
                  maxResults={20}
                />
              ) : (
                getDataItem(projectInformation.autReportingCountry)
              )}
            </>
          )}
        </InformationItem>
      </ProjectDataSection>
      <ProjectDataSectionTwo fr1={1} fr2={2}>
        <InformationItem>
          {projectInformationVisibility.deliveryOrg && (
            <>
              <TitleItem>Delivery organisation:</TitleItem>
              {editInfo.deliveryOrgProperties.editable ? (
                <EditableSearchableDropdown
                  value={sectionInput.input.deliveryOrgId !== null ? sectionInput.input.deliveryOrgId : ""}
                  onValueChanged={value =>
                    setSectionInputWithoutInherited({ ...sectionInput.input, deliveryOrgId: parseNumberOrNull(value) })
                  }
                  options={organisationKeysData.organisationKeys.deliveryOrgs.map(deliveryOrg => {
                    return { id: deliveryOrg.id, description: deliveryOrg.description };
                  })}
                  disabled={!editInfo.deliveryOrgProperties.editable}
                  error={inputErrors.includes(DetailsField.deliveryOrgId)}
                  searchable={true}
                  maxResults={20}
                  inputWidth={170}
                />
              ) : (
                getDataItem(projectInformation.deliveryOrg)
              )}
            </>
          )}
        </InformationItem>
        <InformationItem>
          {projectInformationVisibility.deliveryLocation && (
            <>
              <TitleItem>Delivery location</TitleItem>
              {editInfo.deliveryLocationProperties.editable ? (
                <EditableSearchableHierarchyDropdown
                  selected={{
                    isSingleSelection: true,
                    value:
                      sectionInput.input.deliveryLocationId !== null
                        ? sectionInput.input.deliveryLocationId.toString()
                        : "",
                  }}
                  onValueChanged={value =>
                    setSectionInputWithoutInherited({
                      ...sectionInput.input,
                      deliveryLocationId: parseNumberOrNull(value),
                    })
                  }
                  onDescChanged={() => {}}
                  hierarchy={deliveryLocationDropdownItems}
                  disabled={!editInfo.deliveryLocationProperties.editable}
                  error={inputErrors.includes(DetailsField.deliveryLocationId)}
                  searchable={true}
                  maxResults={20}
                  inputWidth={620}
                />
              ) : (
                getDataItem(projectInformation.deliveryLocation)
              )}
            </>
          )}
        </InformationItem>
      </ProjectDataSectionTwo>
    </DataSection>
  ) : null;
};

export default connect(mapStateToProps, mapDispatchToProps)(InformationDataSection);
