import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import styled from "styled-components";
import { useQuery } from "@apollo/client/react/hooks";
import { faChevronCircleRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { resetProjectHierarchy } from "../../../../actions/hierarchyActions";
import { cancelNewProject, setProjectRelatingId } from "../../../../actions/projectActions";
import { disabledGrey, settingGreen, filterGreen } from "../../../../common/colors";
import routes from "../../../../common/routes";
import { AppState, EntityTypeId, ProjectTechnicalTypesResult, SearchProject } from "../../../../common/types";
import { GET_PROJECT_TECHNICAL_TYPES } from "../ProjectBasics/InformationSection/queries";
import RelatingProjectSection from "./RelatingProjectSection";
import RelationsSection from "./RelationsSection";
import { createNewProject, pollForProjectCreationReady } from "./queries";
import { ActionButton, ButtonContainer } from "../../../../common/components";
import { getEmptyProjectRelationSectionInput } from "../../../../common/constants";
import ProjectTypeIcon from "../../../ProjectTypeIcon/ProjectTypeIcon";
import { FullscreenWrapper, Container, ContentContainer, SectionTitle, TitleContainer, ContentSection } from "./utils";

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    cancelNewProject: () => {
      dispatch(cancelNewProject());
      dispatch(resetProjectHierarchy());
    },
    setProjectRelatingId: (projectId: number, relatingProjectId: undefined | number) =>
      dispatch(setProjectRelatingId(projectId, relatingProjectId)),
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    relationInput: state.projectState.projectInput
      ? state.projectState.projectInput.projectRelations
      : getEmptyProjectRelationSectionInput(),
    relationInputErrors: state.projectState.projectErrors.relations,
  };
};

function CreateProjectDialog(
  props: ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & RouteComponentProps
): React.ReactElement {
  const { cancelNewProject, history, relationInput, relationInputErrors, setProjectRelatingId } = props;
  const [projectType, setProjectType] = useState<EntityTypeId | null>(EntityTypeId.ProjectWithRelations);
  const [relatingProjectInput, setRelatingProject] = useState<SearchProject | null>(null);
  const [pristine, setPristine] = useState<boolean>(true);
  useEffect(() => setPristine(false), [projectType, relatingProjectInput, relationInput.input]);

  const {
    loading: projectTypesLoading,
    data: projectTypesData,
    error: projectTypesError,
  } = useQuery<ProjectTechnicalTypesResult>(GET_PROJECT_TECHNICAL_TYPES, {});

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const {
    create,
    newProjectId,
    applicationModifiedDateTime,
    loading: createNewProjectLoading,
    error: createNewProjectError,
  } = createNewProject();

  const { pollForReady, ready, loading: pollingForReady, error: pollForReadyError } = pollForProjectCreationReady();

  useEffect(() => {
    if (newProjectId !== undefined && applicationModifiedDateTime !== undefined && !createNewProjectLoading) {
      pollForReady(newProjectId, applicationModifiedDateTime);
    }
  }, [newProjectId, applicationModifiedDateTime, createNewProjectLoading]);

  useEffect(() => {
    const ongoingPolling = localStorage.getItem("projectCreationPolling");
    if (ongoingPolling) {
      const pollingData = JSON.parse(ongoingPolling);
      pollForReady(pollingData.projectId, pollingData.applicationModifiedDateTime);
    }
  }, [pollForReady]);

  useEffect(() => {
    if (newProjectId && ready) {
      setProjectRelatingId(newProjectId, relatingProjectInput ? relatingProjectInput.id : undefined);
    }
  }, [newProjectId, ready]);

  const selectableProjectTypes = [
    EntityTypeId.ProjectWithRelations,
    EntityTypeId.ProjectWithoutRelations,
    EntityTypeId.ProjectAdjustment,
    EntityTypeId.ManualAdjustment,
  ];
  const filteredProjectTypes =
    projectTypesData &&
    projectTypesData.projectTechnicalTypes.filter(projectType => selectableProjectTypes.includes(projectType.id));

  const isProjectWithRelations = projectType === EntityTypeId.ProjectWithRelations;
  const isProjectAdjustment = projectType === EntityTypeId.ProjectAdjustment;

  const loading = createNewProjectLoading || pollingForReady;
  const tryAgain = (createNewProjectError || pollForReadyError) && pristine;

  return (
    <FullscreenWrapper>
      <Container>
        {filteredProjectTypes && !projectTypesLoading && (
          <ContentContainer>
            <TitleContainer>Project creation</TitleContainer>
            <ContentSection>
              <SectionTitle>Project technical type:</SectionTitle>
              <ProjectTypeSelection>
                {([
                  [EntityTypeId.ProjectWithRelations, "With relations"],
                  [EntityTypeId.ProjectWithoutRelations, "Without relations"],
                  [EntityTypeId.ProjectAdjustment, "Project adj."],
                  [EntityTypeId.ManualAdjustment, "Manual adj."],
                ] as [EntityTypeId, string][]).map(([type, title]) => {
                  return (
                    <ProjectTypeButton
                      key={"type-" + type}
                      onClick={() => setProjectType(type)}
                      selected={type === projectType}
                    >
                      <ProjectTypeIcon projectTechnicalType={type} />
                      {title}
                    </ProjectTypeButton>
                  );
                })}
              </ProjectTypeSelection>
            </ContentSection>
            {isProjectWithRelations && relationInput && <RelationsSection />}
            {isProjectAdjustment && (
              <RelatingProjectSection relatingProject={relatingProjectInput} setRelatingProject={setRelatingProject} />
            )}
            <ContentSection>
              <ButtonContainer
                disabledSave={
                  projectType === null ||
                  (relationInputErrors && isProjectWithRelations) ||
                  (isProjectWithRelations && relationInput && relationInput.input.length === 0) ||
                  (isProjectAdjustment && !relatingProjectInput)
                }
                buttonLoading={loading}
              >
                <ActionButton
                  onClick={() => {
                    if (
                      tryAgain &&
                      newProjectId !== undefined &&
                      applicationModifiedDateTime !== undefined &&
                      !createNewProjectLoading
                    ) {
                      pollForReady(newProjectId, applicationModifiedDateTime);
                    } else if (projectType) {
                      create(
                        projectType,
                        isProjectWithRelations ? relationInput.input : undefined,
                        isProjectAdjustment && relatingProjectInput ? relatingProjectInput.id : undefined
                      );
                      setPristine(true);
                    }
                  }}
                  disabled={
                    projectType === null ||
                    (relationInputErrors && isProjectWithRelations) ||
                    loading ||
                    (isProjectWithRelations && relationInput && relationInput.input.length === 0) ||
                    (isProjectAdjustment && !relatingProjectInput)
                  }
                >
                  {loading ? "Loading" : tryAgain ? "Try again" : "Continue"}
                  <FontAwesomeIcon
                    icon={loading ? faSpinner : faChevronCircleRight}
                    size="1x"
                    spin={loading}
                    color={
                      projectType === null ||
                      (relationInputErrors && projectType === 1) ||
                      loading ||
                      (projectType === 1 && relationInput && relationInput.input.length === 0) ||
                      (isProjectAdjustment && !relatingProjectInput)
                        ? disabledGrey
                        : settingGreen
                    }
                  />
                </ActionButton>
                <ActionButton
                  onClick={() => {
                    cancelNewProject();
                    history.push(routes.MAIN);
                  }}
                >
                  Cancel
                </ActionButton>
              </ButtonContainer>
            </ContentSection>
          </ContentContainer>
        )}
        {projectTypesLoading && <LoadingContainer>Loading...</LoadingContainer>}
        {projectTypesError && (
          <ErrorContainer>
            Error loading project data: {projectTypesError.message} {projectTypesError.graphQLErrors}{" "}
            {projectTypesError.networkError}
          </ErrorContainer>
        )}
        {pristine && !loading && createNewProjectError && <ErrorContainer>{createNewProjectError}</ErrorContainer>}
        {pristine && !loading && pollForReadyError && <ErrorContainer>{pollForReadyError}</ErrorContainer>}
      </Container>
    </FullscreenWrapper>
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateProjectDialog));

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

const ProjectTypeSelection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const ProjectTypeButton = styled.button<{ selected: boolean }>`
  display: flex;
  margin: 2px;
  padding: 4px;
  padding-right: 8px;
  border: 1px solid ${settingGreen};
  color: ${settingGreen};
  font-weight: bold;
  background: ${filterGreen};
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  svg {
    margin-left: 5px;
    margin-right: 5px;
  }
  ${({ selected }) => selected && `font-weight: bold; background: #fff; padding-top: 8px; padding-bottom: 8px;`}
`;
