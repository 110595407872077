import React, { useMemo, useState } from "react";
import { gql } from "@apollo/client";
import styled from "styled-components";
import { useQuery } from "@apollo/client/react/hooks";
import {
  settingGreen,
  valmetGreyHeader,
  valmetGreyLightTable,
  valmetGreyREC,
  valmetGreyTable,
} from "../../../../common/colors";
import { LogEvent, ProjectId, ProjectLogsResult } from "../../../../common/types";
import { LogMessageItem, ParsedLogEvent, ParsedYearlyLogs, StatusMessage } from "./util";
import LoadingView from "../../../LoadingView";
import YearlyLogs from "./YearlyLogs";
import { TabButton, TabButtonsContainer, ToggleSwitch } from "../../../../common/components";

interface ProjectLogsPanelProps {
  projectId: ProjectId;
}

const GET_PROJECT_LOGS = gql`
  query GetProjectLogs($projectId: ProjectId!) {
    projectLogs(projectId: $projectId) {
      projectId
      grouped {
        period
        subGroups {
          period
          logs {
            actionTypeId
            logDateTime
            message
            batchRid
            executionTimeSeconds
            user
          }
        }
      }
    }
  }
`;

function ProjectLogsPanel(props: ProjectLogsPanelProps) {
  const { projectId } = props;
  const [filterInput, setFilterInput] = useState("");
  const [yearRange, setYearRange] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState<string | null>(null);
  const [hideScheduledLoadingLogs, setHideScheduledLoadingLogs] = useState(true);
  const { data, loading, error } = useQuery<ProjectLogsResult>(GET_PROJECT_LOGS, {
    variables: {
      projectId,
    },
    fetchPolicy: "no-cache",
    onCompleted: res => {
      if (res.projectLogs && res.projectLogs.grouped.length > 0) {
        const years = res.projectLogs.grouped.map(g => g.period);
        setYearRange(years);
        setSelectedYear(years[0]);
      }
    },
  });

  const parsedGroupedLogs: ParsedYearlyLogs[] = useMemo(() => {
    function convert(logEvent: LogEvent): ParsedLogEvent {
      const { actionTypeId, logDateTime, message, batchRid, executionTimeSeconds, user } = logEvent;
      const parsedMessages: LogMessageItem[] = JSON.parse(message);
      let size = 0;
      const messageString = parsedMessages.reduce((acc, item) => {
        switch (item.type) {
          case "header":
            size += 1;
            return acc + item.header + "\n";
          case "paragraph":
            size += 1;
            return acc + item.paragraph + "\n";
          case "list":
            size += item.items.length;
            return acc + item.items.join("\n") + "\n";
        }
        return acc;
      }, "");
      return {
        actionTypeId,
        logDateTime,
        message: parsedMessages,
        messageString,
        size,
        batchRid,
        executionTimeSeconds,
        user,
      };
    }

    if (data && data.projectLogs) {
      return data.projectLogs.grouped.map(group => {
        return {
          period: group.period,
          subGroups: group.subGroups.map(subGroup => {
            return {
              period: subGroup.period,
              logs: subGroup.logs.map(convert),
            };
          }),
        };
      });
    } else return [];
  }, [data]);

  const header = (
    <Header>
      <TabButtonsContainer>
        {yearRange.map(year => (
          <TabButton key={year} onClick={() => setSelectedYear(year)} selected={selectedYear === year}>
            {year}
          </TabButton>
        ))}
      </TabButtonsContainer>
      <Controls>
        <ToggleSwitch
          checked={hideScheduledLoadingLogs}
          onChange={value => setHideScheduledLoadingLogs(value)}
          text={"Hide scheduled loading"}
          color={settingGreen}
          fontSize={"12px"}
        />
        <FilterInput placeholder="Filter items..." value={filterInput} onChange={e => setFilterInput(e.target.value)} />
      </Controls>
    </Header>
  );

  const content = parsedGroupedLogs.map(yearlyLogs => {
    return (
      <YearlyLogs
        key={yearlyLogs.period}
        logs={yearlyLogs}
        filterInput={filterInput}
        hideScheduledLoadingLogs={hideScheduledLoadingLogs}
        selectedYear={selectedYear}
      />
    );
  });

  return loading ? (
    <LoadingView />
  ) : (
    <Container>
      <Background>
        {!loading && header}
        {!loading && (!data || error) && <StatusMessage>Error loading changelog.</StatusMessage>}
        {!loading && data && content}
      </Background>
    </Container>
  );
}

export default ProjectLogsPanel;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 0px;
  padding-bottom: 68px;
`;

const Background = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  /* background-color: ${valmetGreyLightTable};
  @media (min-width: 901px) {
    padding-left: 200px;
    padding-right: 200px;
    width: 100vw;
  }
  @media (max-width: 900px) {
    padding-left: 20px;
    padding-right: 20px;
    width: 100vw;
  }*/
  @media (min-width: 1250px) {
    width: 60vw;
  }
  @media (max-width: 1250px) {
    width: 90vw;
  }
  transition: width 100ms linear;
  padding-bottom: 8px;
`;

const Header = styled.div`
  box-sizing: border-box;
  /*background-color: ${valmetGreyTable};*/
  color: ${valmetGreyHeader};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px 0px 0px;
  font-size: 16px;
  font-weight: bold;
`;

const FilterInput = styled.input`
  display: inline-block;
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 14px;
  min-width: 50px;

  padding: 4px;
  border-radius: 3px;
  margin-left: 10px;
  margin-right: 10px;

  &:focus {
    background-color: ${valmetGreyREC};
  }

  ::placeholder {
    /* Firefox, Chrome, Opera */
    opacity: 1;
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;
